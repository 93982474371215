import { useFormikContext } from 'formik';

import { Col, Input, Row, Switch } from '@perts/ui';
import { useTerms } from 'components/TermsContext';

export const MembersManagerFormSwitch = () => {
  const { values, setFieldValue, errors, touched, dirty } =
    useFormikContext<any>();
  const terms = useTerms();

  const onChangeSwitch = (e) => {
    // Toggle switch value.
    setFieldValue('isManager', e.target.checked);

    // Any interaction with the form means it's no longer indeterminate.
    if (values.isManagerIndeterminate) {
      setFieldValue('isManagerIndeterminate', false);
    }
  };

  const labelText = values.isManagerIndeterminate ? (
    <strong>{terms.groupMembers.toLowerCase()} have mixed settings.</strong>
  ) : values.isManager ? (
    <span>
      <strong>Enabled</strong>: Selected {terms.groupMembers.toLowerCase()} are{' '}
      {terms.groupManagers.toLowerCase()}
    </span>
  ) : (
    <span>
      <strong>Disabled</strong>: Selected {terms.groupMembers.toLowerCase()}{' '}
      aren't {terms.groupManagers.toLowerCase()}
    </span>
  );

  return (
    <Row>
      <Col>
        <Input
          id="isManager"
          name="isManager"
          label={labelText}
          aria-label="Promote/Demote member"
          labelPlacement="end"
          component={Switch}
          checked={values.isManager}
          indeterminate={values.isManagerIndeterminate}
          onChange={onChangeSwitch}
          displayError
          error={dirty && touched.isManager && errors.isManager}
        />
      </Col>
    </Row>
  );
};
