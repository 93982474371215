import keyBy from 'lodash/keyBy';
import { locales } from '../i18n';

import { CompositeMetricConfig, MetricItemConfig } from './types';

export const mathPilot22LearningFromMisconceptionsItemConfig: MetricItemConfig[] =
  [
    {
      label: 'misconceptions_teacher',
      likertN: 6,
      minGood: 5,
      prompts: {
        [locales.ENGLISH]: `My teacher wants to understand our wrong answers as much as our right answers.`,
        [locales.SPANISH]: `Mi maestro quiere entender nuestras respuestas incorrectas tanto como nuestras respuestas correctas.`,
      },
    },
    {
      label: 'misconceptions_classmates',
      likertN: 6,
      minGood: 5,
      prompts: {
        [locales.ENGLISH]: `I learn from my classmatesʼ incorrect answers to math problems.`,
        [locales.SPANISH]: `Aprendo de las respuestas incorrectas de mis compañeros a los problemas de matemáticas.`,
      },
    },
  ];

export const mathPilot22LearningFromMisconceptionsConfig: CompositeMetricConfig =
  {
    label: 'learning-from-misconceptions',
    items: mathPilot22LearningFromMisconceptionsItemConfig,
    itemIndex: keyBy(mathPilot22LearningFromMisconceptionsItemConfig, 'label'),
  };
