import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';

export const supportiveTeachingItemConfig: MetricItemConfig[] = [
  {
    label: 'supportive_teaching_1',
    likertN: 6,
    minGood: 5,
    prompts: {
      en:
        'This teacher regularly checks in to make sure we understand ' +
        'the class material.',
      es:
        'Este maestro regularmente se asegura de que entendemos el ' +
        'material de la clase.',
    },
  },
  {
    label: 'supportive_teaching_2',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'This teacher explains things in different ways if we’re confused.',
      es:
        'Este maestro explica las cosas de diferentes maneras si ' +
        'estamos confundidos.',
    },
  },
  {
    label: 'supportive_teaching_3',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'This teacher knows my strengths and weaknesses in this class.',
      es: 'Este maestro reconoce mis fortalezas y debilidades en esta clase.',
    },
  },
];

export const supportiveTeachingConfig: CompositeMetricConfig = {
  label: 'supportive-teaching',
  items: supportiveTeachingItemConfig,
  itemIndex: keyBy(supportiveTeachingItemConfig, 'label'),
};
