import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';

export const feedbackForGrowthItemConfig: MetricItemConfig[] = [
  {
    label: 'feedback_for_growth_1',
    likertN: 6,
    minGood: 5,
    prompts: {
      en:
        'In this class, it is more important to try than to get things ' +
        'right the first time.',
      es:
        'En esta clase, es más importante el esfuezerzo que tener la ' +
        'correcta la primera vez.',
    },
  },
  {
    label: 'feedback_for_growth_2',
    likertN: 6,
    minGood: 5,
    prompts: {
      en:
        'This teacher lets me know they believe I can do well in this ' +
        'class.',
      es:
        'Este maestro me hace saber que cree que me irá bien en esta ' +
        'clase.',
    },
  },
  {
    label: 'feedback_for_growth_3',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'I get specific suggestions about how to improve my work.',
      es: 'Recibo sugerencias específicas sobre cómo mejorar mi trabajo.',
    },
  },
];

export const feedbackForGrowthConfig: CompositeMetricConfig = {
  label: 'feedback-for-growth',
  items: feedbackForGrowthItemConfig,
  itemIndex: keyBy(feedbackForGrowthItemConfig, 'label'),
};
