import { selectUserIsAdmin, useCurrentUserQuery } from 'models';

import { useUpdateRecentProgram } from './useUpdateRecentProgram';

import { FixedActionbarContainer } from '@perts/ui';
import { HomeTemporaryStyles } from './HomeTemporaryStyles';
import { HomeActionbar } from './HomeActionbar';
import { HomeRender } from './HomeRender';

export const Home = () => {
  const user = useCurrentUserQuery();

  // Update user with most recently viewed program.
  useUpdateRecentProgram();

  if (user.isLoading || !user.isSuccess) {
    return null;
  }

  const isAdmin = selectUserIsAdmin(user.data);

  return (
    <HomeTemporaryStyles>
      <FixedActionbarContainer>
        <HomeActionbar mayAddNetworks={isAdmin} />
      </FixedActionbarContainer>

      <HomeRender user={user.data} />
    </HomeTemporaryStyles>
  );
};
