import { isEqual, sortBy } from 'lodash';
import { useState } from 'react';

import { programMetricIndex } from '@perts/config';
import { OrganizationResults } from '@perts/model';
import {
  Card,
  Col,
  HelpText,
  PageBreak,
  Row,
  Show,
  Text,
  useBroadcast,
} from '@perts/ui';

import { channelFullwindow, helpArticles } from 'config';
import { Group, Metric, Program, User } from 'models';
import { toGroupReportSettings } from 'pages';
import { usePermissions, useTitle } from 'utils';

import { ArchivedWarning } from 'components/ArchivedWarning';
import { ExperienceBySurveyChart } from 'components/ExperienceBySurveyChart';
import { useTerms } from 'components/TermsContext';
import {
  getEnabledMetrics,
  MetricSection,
  removeDisabledMetricsFromResults,
  ReportStyled,
} from '../components';

import {
  CommunicationFidelity,
  ExperienceOverview,
  ReportNavigation,
  SurveySampleOverviewTable,
  TargetGroupTable,
} from '../';
import ReportTitleRow from '../components/ReportTitleRow';

type Props = {
  archived: boolean;
  group: Group;
  lastRun: string;
  program: Program;
  programMetrics: Metric[];
  reportMetrics: Metric[];
  results: OrganizationResults;
  user: User;
};

export const GroupReport23Render = ({
  archived,
  group,
  lastRun,
  program,
  programMetrics,
  reportMetrics,
  results: unfilteredResults,
  user,
}: Props) => {
  const { mayGroupEdit } = usePermissions();
  const terms = useTerms();

  useTitle(`${terms.group} Report: ${group.name}`);

  const [isFullwindow, setFullwindow] = useState(false);
  const { listen } = useBroadcast(channelFullwindow);
  listen((shouldExpand) => setFullwindow(shouldExpand));

  const programMetricConfig = programMetricIndex[program.label];

  const enabledMetrics = getEnabledMetrics(group, reportMetrics);

  const results = removeDisabledMetricsFromResults(
    unfilteredResults,
    enabledMetrics,
  );

  const overallDataByMetric = Object.entries(results.experience).map(
    ([metricLabel, experienceData]) =>
      experienceData.composite.all_participants,
  );
  const mostTimePointsCollected = Math.max(
    ...overallDataByMetric.map(
      (cycleValues) => cycleValues.filter((x) => x !== null).length,
    ),
  );

  const metricBySurvey = Object.entries(results.experience).map(
    ([metricLabel, metricResults]) => ({
      childName:
        enabledMetrics.find((m) => m.label === metricLabel)?.name || '',
      childLabel: metricLabel,
      ratedPositive: metricResults.composite.all_participants,
    }),
  );
  const metricBySurveySorted = sortBy(metricBySurvey, 'childName');

  return (
    <ReportStyled fullscreen={isFullwindow}>
      <ReportNavigation
        metrics={enabledMetrics}
        program={program}
        settingsActive={!isEqual(enabledMetrics, reportMetrics)}
        showTargetGroup={true}
        toSettings={mayGroupEdit ? toGroupReportSettings(group.uid) : null}
      />

      <Show when={archived}>
        <ArchivedWarning isReport={true} />
      </Show>

      <ReportTitleRow>
        <Col cols={1}></Col>
        <Col cols={10}>
          <h1>Overall Results</h1>
        </Col>
      </ReportTitleRow>

      {results.fidelity && program.label !== 'sesStudent23' && (
        <section id="survey-fidelity">
          <CommunicationFidelity
            fidelityMakeBetter={results.fidelity.make_better}
          />
        </section>
      )}

      <section id="student-experience-overview">
        <ExperienceOverview
          experienceData={results.experience}
          metrics={enabledMetrics}
          programLabel={program.label}
          sampleData={results.sample}
        />
      </section>

      <PageBreak />
      <section id="metric-trends">
        <Card>
          <Card.Title>
            <Row noPageBreakInside>
              <Col cols={6}>
                <Text as="h2">{terms.condition} Trends</Text>
              </Col>
              <Col cols={6} vAlign="center" hAlign="flex-end">
                <HelpText articleId={helpArticles.learningConditionTrendsTable}>
                  Tips for using this
                </HelpText>
              </Col>
            </Row>
          </Card.Title>

          <Card.Content>
            <Row>
              <Col>
                <Show when={mostTimePointsCollected > 1}>
                  <ExperienceBySurveyChart
                    ariaLabel={`${terms.condition} trends by survey`}
                    experienceByChild={metricBySurveySorted}
                    customLegend
                  />
                </Show>
                <Show when={mostTimePointsCollected <= 1}>
                  <p>
                    <em>Not enough data collected yet.</em>
                  </p>
                </Show>
              </Col>
            </Row>
          </Card.Content>
        </Card>
      </section>

      <PageBreak />
      <h1>Detailed Results</h1>

      {enabledMetrics.map((metric) =>
        metric ? (
          <div key={metric.label}>
            <MetricSection
              metric={metric}
              experienceData={results.experience[metric.label]}
              key={metric.label}
              sampleData={results.sample.by_metric[metric.label]}
              sampleSizeTotal={results.sample.all_responses.all_participants}
              programMetricConfig={programMetricConfig}
              programLabel={program.label}
            />
            <PageBreak />
          </div>
        ) : null,
      )}

      <section id="survey-response-rates">
        <Card>
          <Card.Title>
            <Row>
              <Col cols={6}>
                <Text as="h2">Participation by Subpopulation</Text>
              </Col>
              <Col cols={6} vAlign="center" hAlign="flex-end">
                <HelpText
                  articleId={helpArticles.participationBySubpopulationTable}
                >
                  Tips for using this
                </HelpText>
              </Col>
            </Row>
          </Card.Title>

          <Card.Content>
            <SurveySampleOverviewTable
              groupId={group.short_uid}
              lastRun={lastRun}
              programLabel={program.label}
              sampleData={results.sample.all_responses}
            />
          </Card.Content>
        </Card>
      </section>

      {results.target_group && (
        <section id="focal-group-summary">
          <Card>
            <Card.Title>
              <Row>
                <Col cols={6}>
                  <Text as="h2">Focal Group Summary</Text>
                </Col>
                <Col cols={6} vAlign="center" hAlign="flex-end">
                  <HelpText articleId={helpArticles.targetGroupSummary}>
                    Tips for using this
                  </HelpText>
                </Col>
              </Row>
            </Card.Title>

            <Card.Content>
              <TargetGroupTable data={results.target_group} user={user} />
            </Card.Content>
          </Card>
        </section>
      )}
    </ReportStyled>
  );
};
