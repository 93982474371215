import {
  IconEnvelope,
  IconCaretRight,
  IconGoogle,
  IconIdBadge,
  IconLock,
  IconSortDown,
  IconSortUp,
  IconUserCircle,
  IconRule,
} from '@perts/ui';
import styled from 'styled-components/macro';

export * from './SortByDirectionToggle';

export const SortByOptionName = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export const IconFromTo = styled(IconCaretRight)``;

export const IconAsc = styled(IconSortUp)`
  margin-top: 10px;
`;

export const IconDesc = styled(IconSortDown)``;

const iconActive = (IconComponent) => styled(IconComponent)`
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.text};
  border: 1px solid ${(props) => props.theme.colors.text};
  border-radius: 2px;
  padding: 2px;
`;

const iconInactive = (IconComponent) => styled(IconComponent)`
  color: ${(props) => props.theme.colors.text};
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.text};
  border-radius: 2px;
  padding: 2px;
`;

// Leads
export const IconUserCircleLead = iconActive(IconUserCircle);
export const IconUserCircleNotLead = iconInactive(IconUserCircle);

// Invitation
export const IconEnvelopePending = iconActive(IconEnvelope);
export const IconEnvelopeAccepted = iconInactive(IconEnvelope);

// Roster Locking
export const IconLockEnabled = iconActive(IconLock);
export const IconLockDisabled = iconInactive(IconLock);

// Sign-On Type
export const IconEnvelopeEnabled = iconActive(IconEnvelope);
export const IconGoogleEnabled = iconActive(IconGoogle);
export const IconIdBadgeEnabled = iconActive(IconIdBadge);

// Sign-On Rule
export const IconRuleEnabled = iconActive(IconRule);
export const IconRuleDisabled = iconInactive(IconRule);
