import keyBy from 'lodash/keyBy';
import { locales } from '../i18n';

import { CompositeMetricConfig, MetricItemConfig } from './types';
import { options } from './answerOptions/HowOften5Options';

export const mathPilot22AutonomyOverProcessItemConfig: MetricItemConfig[] = [
  {
    label: 'autonomy_work_alone',
    minGood: 4,
    prompts: {
      [locales.ENGLISH]: `I can CHOOSE when to work alone, with a partner, or with a group.`,
      [locales.SPANISH]: `Puedo ELEGIR cuándo trabajar solo, en pareja o en grupo.`,
    },
    options,
  },
  {
    label: 'autonomy_choose_share',
    minGood: 4,
    prompts: {
      [locales.ENGLISH]: `I can CHOOSE how to share my thinking by writing, drawing, talking, graphing, or other ways.`,
      [locales.SPANISH]: `Puedo ELEGIR cómo compartir mi pensamiento por escribir, dibujar, hablar, hacer gráficos u otras formas.`,
    },
    options,
  },
];

export const mathPilot22AutonomyOverProcessConfig: CompositeMetricConfig = {
  label: 'autonomy-over-process',
  items: mathPilot22AutonomyOverProcessItemConfig,
  itemIndex: keyBy(mathPilot22AutonomyOverProcessItemConfig, 'label'),
};
