import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';

export const identitySafetyItemConfig: MetricItemConfig[] = [
  {
    label: 'idsafety_judged',
    likertN: 6,
    likertReverse: true,
    minGood: 5,
    prompts: {
      en:
        'In this class, I worry about being judged negatively based on my ' +
        'group membership(s) (e.g., race, gender, social class, etc.).',
    },
  },
  {
    label: 'idsafety_evaluated',
    likertN: 6,
    likertReverse: true,
    minGood: 5,
    prompts: {
      en:
        'In this class, I worry that people’s evaluations of me will be ' +
        'affected by my group membership(s) (e.g., race, gender, social ' +
        'class, etc.).',
    },
  },
  {
    label: 'idsafety_surprised',
    likertN: 6,
    likertReverse: true,
    minGood: 5,
    prompts: {
      en:
        'People here would be surprised if I, or someone like me, did well ' +
        'in this class.',
    },
  },
];

export const identitySafetyConfig: CompositeMetricConfig = {
  label: 'identity-safety',
  items: identitySafetyItemConfig,
  itemIndex: keyBy(identitySafetyItemConfig, 'label'),
};
