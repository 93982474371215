import { useState, useEffect, useContext } from 'react';
import { Formik, Form } from 'formik';
import { User, Class } from 'models';
import pluralize from 'pluralize';
import {
  Modal,
  Button,
  Row,
  Col,
  CheckboxGroup,
  HelpText,
  useFormError,
} from '@perts/ui';
import * as Yup from 'yup';
import useToggles from 'utils/useToggles';
import { isEmpty } from 'lodash';
import { helpArticles, USER_OWNED_TEAMS_MAX } from '../../../config';
import TermsContext, { Terms } from 'components/TermsContext';

export type ClassesEditUsersValuesProps = {
  owned_teams?: { [uid: string]: string[] };
};

export type ClassesEditUsersFormProps = {
  classes: Class[];
  facilitators: User[];
  onSubmit: (values: ClassesEditUsersValuesProps) => void;
  close: () => void;
  checkedFacitilitatorIds: string[];
  indeterminateFacilitatorIds: string[];
};

const createSchemaShapeFromFacilitators = (facilitators: User[]) =>
  Object.fromEntries(facilitators.map(({ uid }) => [uid, Yup.array()]));

// We can determine that a user is being added as Class Lead if their values
// array (owned_teams) has grown in size.
const isUserAddingClass = (uid, initialValues, currentValues) =>
  currentValues[uid].length > initialValues[uid].length;

const CommaSeparatedUserList = ({ facilitators }) => (
  <>{facilitators.map((f) => `${f.name} (${f.email})`).join(', ')}</>
);

const getFormSchema = (
  name: string,
  facilitators: User[],
  terms: Terms,
  initialValues,
) =>
  Yup.object().shape({
    [name]: Yup.object()
      .shape(createSchemaShapeFromFacilitators(facilitators))
      .test(
        'at-least-one',
        `There must be at least one ${terms.classManager.toLowerCase()} in each ${terms.class.toLowerCase()}.`,
        (values) => Object.values(values).some((value) => !isEmpty(value)),
      )
      .test(
        'too-many-classes',
        ({ value }) => {
          const facilitatorIds = Object.keys(value);

          // Find any facilitators being added as Class Lead that have too many
          // Classes (owned_teams) already.
          const facilitatorsAddingClassesWithTooMany = facilitatorIds
            .map((facilitatorId) => {
              const selectedFacilitator = facilitators.find(
                (f) => f.uid === facilitatorId,
              );

              const selectedIsAddingClass = isUserAddingClass(
                facilitatorId,
                initialValues,
                value,
              );

              if (
                selectedIsAddingClass &&
                selectedFacilitator &&
                selectedFacilitator.owned_teams.length >= USER_OWNED_TEAMS_MAX
              ) {
                return selectedFacilitator;
              }

              return false;
            })
            .filter(Boolean) as User[];

          return (
            <>
              <CommaSeparatedUserList
                facilitators={facilitatorsAddingClassesWithTooMany}
              />{' '}
              cannot be assigned to more than {USER_OWNED_TEAMS_MAX}{' '}
              {terms.classes}. Reach out to{' '}
              <a href="mailto:support@perts.net">support@perts</a>.
            </>
          );
        },
        (values) => {
          const facilitatorIds = Object.keys(values);

          // Find any facilitators being added as Class Lead that have too many
          // Classes (owned_teams) already.
          const facilitatorsAddingClassesWithTooMany = facilitatorIds
            .map((facilitatorId) => {
              const selectedFacilitator = facilitators.find(
                (f) => f.uid === facilitatorId,
              );

              const selectedIsAddingClass = isUserAddingClass(
                facilitatorId,
                initialValues,
                values,
              );

              if (
                selectedIsAddingClass &&
                selectedFacilitator &&
                selectedFacilitator.owned_teams.length >= USER_OWNED_TEAMS_MAX
              ) {
                return selectedFacilitator;
              }

              return false;
            })
            .filter(Boolean) as User[];

          return facilitatorsAddingClassesWithTooMany.length === 0;
        },
      ),
  });

export const ClassesEditUsersForm = ({
  close,
  onSubmit,
  classes: rawClasses = [],
  facilitators = [],
  checkedFacitilitatorIds,
  indeterminateFacilitatorIds,
}: ClassesEditUsersFormProps) => {
  const [FormError, showFormError] = useFormError();
  const terms = useContext(TermsContext);

  const initialState = {};

  facilitators.forEach(({ uid }) => {
    initialState[uid] = {
      checked: Boolean(checkedFacitilitatorIds.find((cf) => cf === uid)),
      indeterminate: Boolean(
        indeterminateFacilitatorIds.find((cf) => cf === uid),
      ),
    };
  });

  const { isChecked, toggleChecked, isIndeterminate } = useToggles(
    facilitators,
    'uid',
    {
      initialState,
    },
  );

  const [initialValues, setInitialValues] =
    useState<ClassesEditUsersValuesProps>({});

  // Ensure facilitators are defined for each class. The Class type has this
  // property as optional.
  const classes = rawClasses.map((cls) => ({
    ...cls,
    facilitators: cls.facilitators || [],
  }));

  useEffect(() => {
    if (classes.length && isEmpty(initialValues)) {
      // Create initial values to form
      const initialFacilitators = {};
      facilitators.forEach((fcl) => {
        initialFacilitators[fcl.uid] = classes
          .filter((cls) =>
            cls.facilitators.map(({ uid }) => uid).includes(fcl.uid),
          )
          .map(({ uid }) => uid);
      });

      setInitialValues({ owned_teams: initialFacilitators });
    }
  }, [classes, facilitators, initialValues]);

  const allClassIds = classes.map(({ uid }) => uid);

  const ClassesEditUsersFormSchema = getFormSchema(
    'owned_teams',
    facilitators,
    terms,
    initialValues.owned_teams,
  );

  return (
    <Modal close={close}>
      <Modal.Title className="center">Edit {terms.classManagers}</Modal.Title>
      <Row justifyContent="space-between">
        <Col hAlign="flex-end">
          <HelpText articleId={helpArticles.leadsAreAddedToCommunities}>
            {terms.classManagers} are added to all {terms.class.toLowerCase()}
            &rsquo;s {terms.groups.toLowerCase()}.
          </HelpText>
        </Col>
      </Row>

      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={ClassesEditUsersFormSchema}
        validateOnBlur={false}
        onSubmit={async (values) => {
          try {
            // Clear existing form error.
            showFormError(false);

            // Perform form onSubmit.
            await onSubmit(values);

            // Close modal on successful form onSubmit.
            close();
          } catch (error) {
            // Display form error.
            showFormError(true);
          }
        }}
      >
        {({
          isSubmitting,
          isValid,
          setFieldValue,
          values = {},
          errors = {},
          dirty,
        }) => (
          <Form>
            <CheckboxGroup
              type="entityList"
              id="owned_teams"
              name="owned_teams"
              items={facilitators}
              keyId="uid"
              keyName="name"
              value={values.owned_teams}
              isChecked={isChecked}
              isIndeterminate={isIndeterminate}
              toggleChecked={toggleChecked}
              setFieldValue={setFieldValue}
              error={errors.owned_teams}
              listToAdd={allClassIds}
              disabled={isSubmitting}
            />

            <Row>
              <Col>
                <FormError />
              </Col>
            </Row>

            <Row>
              <Col cols={6} colsSm={12}>
                <Button
                  type="button"
                  color="secondary"
                  fullWidth
                  onClick={close}
                  disabled={isSubmitting}
                  fullHeight
                >
                  Cancel
                </Button>
              </Col>

              <Col cols={6} colsSm={12} hAlign="flex-end">
                <Button
                  type="submit"
                  fullWidth
                  disabled={!isValid || isSubmitting || !dirty}
                  loading={isSubmitting}
                  data-testid="submit-btn"
                >
                  Save Changes to {pluralize(terms.class, classes.length, true)}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
