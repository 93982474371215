import { Class, User, userInviteToClass } from 'models';
import { UserClassGroupMap } from './types';

// Send an invite out to each new user, class, AND group combination. If
// a class is joined to more than one group, then multiple invites will be
// sent for that single class.

export const usersInviteToClassesGroups = async (
  invites: UserClassGroupMap,
  users: User[],
  classes: Class[],
) => {
  const usersToInvite = invites.map((invite) => {
    const user = users.find((u) => u.uid === invite?.userId);
    const cls = classes.find((c) => c.uid === invite?.classId);

    return {
      ...invite,
      user,
      cls,
    };
  });

  for (const invite of usersToInvite) {
    if (!invite.user || !invite.cls || !invite.groupId) {
      throw new Error('User, class, and groupId required to invite user.');
    }

    await userInviteToClass(
      {
        email: invite.user.email,
        name: invite.user.name,
      },
      invite.cls,
      { orgId: invite.groupId },
    );
  }
};
