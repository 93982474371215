import styled from 'styled-components';

import type { ProgramMetricConfig } from '@perts/config';
import { OpenResponseResults } from '@perts/model';

const orPrefix = /^or_/;

const ResponseListStyled = styled.ul`
  position: relative;
  list-style-type: none;

  :before {
    content: '\\201C';
    font-family: serif;
    font-size: 48px;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: -1rem;
  }
`;

type ItemProps = {
  cycleResponses: string[][];
  itemLabel: string;
  prompt: string;
};

const OpenResponseItem = ({ cycleResponses, itemLabel, prompt }: ItemProps) => (
  <>
    <p>{prompt}</p>
    <ResponseListStyled>
      {cycleResponses.map((responses, cycleIndex) =>
        responses.map((responseText, responseIndex) => (
          <li key={`${itemLabel}-${cycleIndex}-${responseIndex}`}>
            <em>{responseText}</em> (Survey {cycleIndex + 1})
          </li>
        )),
      )}
    </ResponseListStyled>
  </>
);

type ListProps = {
  metricLabel: string;
  programMetricConfig: ProgramMetricConfig;
  responsesByItem: OpenResponseResults;
};

export const OpenResponseItemList = ({
  metricLabel,
  programMetricConfig,
  responsesByItem,
}: ListProps) => (
  <>
    {Object.entries(responsesByItem).map(([orItemLabel, cycleResponses], i) => {
      if (!orPrefix.test(orItemLabel)) {
        console.error(`Invalid open response item label "${orItemLabel}"`);
        return null;
      }

      const itemLabel = orItemLabel.replace(orPrefix, '');

      const itemConfig =
        programMetricConfig.metricIndex[metricLabel].itemIndex[itemLabel];
      if (!itemConfig) {
        console.error(
          `Did not find itemConfig for metric "${metricLabel}" item ` +
            `"${itemLabel}".`,
        );
        return null;
      }
      return (
        <OpenResponseItem
          key={`${itemLabel}-${i}`}
          prompt={itemConfig.prompts.en || ''}
          cycleResponses={cycleResponses as string[][]}
          itemLabel={itemLabel}
        />
      );
    })}
  </>
);
