import classNames from 'classnames';
import styled from 'styled-components/macro';
import { xor } from 'lodash';
import { InputField } from '../InputField';
import { InputFieldError } from '../InputFieldError';
import InputFieldLabel from '../InputFieldLabel';
import { Input, CheckboxWithLabel } from '../../.';

export type CheckboxGroupProps = {
  // The type 'default' represents a common CheckboxGroup where
  // we want handle an array of values related to input name
  // The type 'entityList' represents a CheckboxGroup used to
  // handle object of entity list related to input name
  type?: 'default' | 'entityList';

  // To allow styled-components wrapping.
  className?: string;

  // Whether the radio is disabled.
  disabled?: boolean;

  // Field ID.
  id: string;

  // Field label.
  label?: any;

  // Field name.
  name: string;

  items: any[];

  keyId: string;

  // Key name from item to show .
  keyName: string;

  // Field value.
  value: any;

  // Field error.
  error?: any;

  // onChange custom handler.
  onChange?: (id: string) => void;

  // onBlur custom handler.
  onBlur?: () => void;

  // isChecked function to validate if an item is checked.
  isChecked: (id: string) => boolean;

  // isIndeterminate function to validate if an item is indeterminate.
  isIndeterminate?: (id: string) => boolean;

  // toggleChecked handler toogle checkbox.
  toggleChecked: (id: string) => void;

  // setFieldValue handler from formik form
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;

  // IDs to add if the checkbox change to checked
  listToAdd?: string[];

  // isDisabled function to validate if an item is disabled.
  isDisabled?: (id: string) => boolean;

  // Whether the label is visible or not.
  showCheckboxLabel?: boolean;

  // Prefix to aria-label attribute.
  prefixAriaLabel?: string;

  // uncheckedCallback function to invoke if the checkbox change to unchecked
  uncheckedCallback?: (id: string) => void;
};

export const CheckboxGroup = ({
  type = 'default',
  className,
  disabled,
  id,
  label,
  name,
  items,
  keyId,
  keyName,
  value,
  error,
  isChecked,
  isIndeterminate,
  toggleChecked,
  setFieldValue,
  listToAdd = [],
  isDisabled,
  showCheckboxLabel = true,
  prefixAriaLabel = '',
  uncheckedCallback,
}: CheckboxGroupProps) => {
  const cx = classNames(className);

  const handleOnChangeDefault = (itemId: string) => {
    // xor return an array with the symmetric difference between the given arrays.
    // If the "itemId" exists on "value" array, the itemId
    // will be removed, else "itemId" will be added.
    const newValue = xor(value, [itemId]);

    // If formik form is used, setFieldValue must be invoked
    // and update value related to itemId from formik values
    if (setFieldValue) {
      setFieldValue(name, newValue);
    }

    if (isChecked(itemId) && uncheckedCallback) {
      uncheckedCallback(itemId);
    }

    toggleChecked(itemId);
  };

  const handleOnChangeEntityList = (itemId: string) => {
    // If formik form is used, setFieldValue must be invoked
    // and update value related to itemId from formik values
    if (setFieldValue) {
      if (!isChecked(itemId)) {
        setFieldValue(`${name}.${itemId}`, listToAdd);
      } else {
        setFieldValue(`${name}.${itemId}`, []);
      }
    }

    if (isChecked(itemId) && uncheckedCallback) {
      uncheckedCallback(itemId);
    }

    toggleChecked(itemId);
  };

  const handleOnChange = (itemId: string) => {
    if (type === 'default') {
      handleOnChangeDefault(itemId);
    } else {
      handleOnChangeEntityList(itemId);
    }
  };

  const getAriaLabel = (labelText: string) => {
    return prefixAriaLabel ? `${prefixAriaLabel}: ${labelText}` : labelText;
  };

  return (
    <InputField className={cx}>
      <LabelContainer>
        {label && <InputFieldLabel htmlFor={id}>{label}</InputFieldLabel>}
      </LabelContainer>

      <CheckboxGroupContainer>
        {items.map((item: any) => (
          <Input
            id={`${name}-${item[keyId]}`}
            key={item[keyId]}
            name={name}
            label={showCheckboxLabel ? item[keyName] : ''}
            aria-label={getAriaLabel(item[keyName])}
            labelPlacement="top"
            checked={isChecked(item[keyId])}
            indeterminate={isIndeterminate && isIndeterminate(item[keyId])}
            disabled={disabled || (isDisabled && isDisabled(item[keyId]))}
            onChange={() => handleOnChange(item[keyId])}
            component={CheckboxWithLabel}
          />
        ))}
      </CheckboxGroupContainer>

      <InputFieldError
        role="alert"
        aria-live="assertive"
        id={`${name}-text-field-error`}
        data-testid={`${name}-text-field-error`}
      >
        {error}
      </InputFieldError>
    </InputField>
  );
};

const CheckboxGroupContainer = styled.div`
  position: relative;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
