import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';

export const learningGoalsItemConfig: MetricItemConfig[] = [
  {
    label: 'learning_goals_1',
    likertN: 6,
    minGood: 5,
    prompts: {
      en:
        'I know how the new things we’re learning in this class ' +
        'connect to what we’ve learned before.',
      es:
        'Sé cómo las cosas nuevas que estamos aprendiendo en esta clase ' +
        'se relacionan con lo que hemos aprendido antes.',
    },
  },
  {
    label: 'learning_goals_2',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'This teacher helps me see my progress as I learn more.',
      es: 'Este maestro me ayuda a ver mi progreso mientras que aprendo más.',
    },
  },
];

export const learningGoalsConfig: CompositeMetricConfig = {
  label: 'learning-goals',
  items: learningGoalsItemConfig,
  itemIndex: keyBy(learningGoalsItemConfig, 'label'),
};
