import { programCatalyzePattern } from 'config';
import { Program } from 'models';

export const defaultTerms = {
  // Network
  network: 'Network',
  networkMember: 'Member',

  // Organization, Group, Community
  group: 'Community',
  groupMember: 'Member',
  groupManager: 'Community Lead',

  // Team, Class
  class: 'Class',
  classManager: 'Class Lead',

  // Participant
  participant: 'Participant',

  // For testing. This provides a value that we're sure won't be overriden by
  // program specific terms so that we can test default terms.
  testTerm: 'foobar',
};

export const getPhrases = (terms, program?: Program) => {
  const programLabel = program?.label || '_default';

  const classNameExplainerDefault = (
    <>
      Try including name, course, and section, like &ldquo;Sanchez, English 1,
      P1.&rdquo;
    </>
  );

  const classNameExplainerCatalyze = (
    <>
      Describe the context all participants share, like &ldquo;Tubman Math
      Department.&rdquo; Ensure it reads well in the question preview below.
    </>
  );

  const conditionTermDefault = 'Learning Condition';
  const conditionTermCatalyze = 'Condition';

  const fidelityBetterDefault = (
    <>
      My instructor will try to use my answers to this survey to make class
      better for me.
    </>
  );
  const fidelityBetterCatalyze = (
    <>
      Leaders will use my answers to this survey to make things better for me
      and for students.
    </>
  );

  const memberAddLabelDefault = 'Educator emails';
  const memberAddLabelCatalyze = 'Email address(es)';

  // One of the ways participants may sign on to the survey. Varies in some
  // programs (e.g. Catalyze) because participants are not students.
  const studentIdPortalTypeDefault = 'Student ID';
  const studentIdPortalTypeCatalyze = 'Staff ID';

  return {
    classNameExplainer: programCatalyzePattern.test(programLabel)
      ? classNameExplainerCatalyze
      : classNameExplainerDefault,
    condition: programCatalyzePattern.test(programLabel)
      ? conditionTermCatalyze
      : conditionTermDefault,
    fidelityMakeBetter: programCatalyzePattern.test(programLabel)
      ? fidelityBetterCatalyze
      : fidelityBetterDefault,
    memberAddLabel: programCatalyzePattern.test(programLabel)
      ? memberAddLabelCatalyze
      : memberAddLabelDefault,
    studentIdPortalType: programCatalyzePattern.test(programLabel)
      ? studentIdPortalTypeCatalyze
      : studentIdPortalTypeDefault,
  };
};
