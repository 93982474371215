import keyBy from 'lodash/keyBy';
import { locales } from '../i18n';

import { CompositeMetricConfig, MetricItemConfig } from './types';

export const mathPilot22RelevanceOfMathItemConfig: MetricItemConfig[] = [
  {
    label: 'relevance_math_skills',
    likertN: 6,
    minGood: 5,
    prompts: {
      [locales.ENGLISH]: `The problem-solving skills Iʼm learning in math are important to my life.`,
      [locales.SPANISH]: `Las habilidades de resolución de problemas que estoy aprendiendo en matemáticas son importantes para mi vida.`,
    },
  },
];

export const mathPilot22RelevanceOfMathConfig: CompositeMetricConfig = {
  label: 'relevance-of-math',
  items: mathPilot22RelevanceOfMathItemConfig,
  itemIndex: keyBy(mathPilot22RelevanceOfMathItemConfig, 'label'),
};
