import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';

export const meaningfulWorkItemConfig: MetricItemConfig[] = [
  {
    label: 'meaningful_work_1',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'In this class, we do meaningful work, not busy work.',
      es:
        'En esta clase, hacemos trabajo significativo, no trabajo para ' +
        'mantenernos ocupados.',
    },
  },
  {
    label: 'meaningful_work_2',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'What we learn in this class is connected to real-life.',
      es: 'Lo que aprendemos en esta clase está relacionado con la vida real.',
    },
  },
  {
    label: 'meaningful_work_3',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'This teacher makes what we’re learning really interesting.',
      es: 'Este maestro hace que lo que estamos aprendiendo muy interesante.',
    },
  },
];

export const meaningfulWorkConfig: CompositeMetricConfig = {
  label: 'meaningful-work',
  items: meaningfulWorkItemConfig,
  itemIndex: keyBy(meaningfulWorkItemConfig, 'label'),
};
