import { difference, intersection, uniq } from 'lodash';
import { Class } from 'models';

// Determine the checked/indeterminate states of facilitator UIDs that are
// associated with the provided classes. Assumption: The classes array has been
// filled with the class `facilitators`.

type Uid = string;

type IdsOfCheckedIndeterminate = {
  checked: Uid[];
  indeterminate: Uid[];
};

export const toggleStatesOfFacilitatorIds = (
  classesFilled: Class[],
): IdsOfCheckedIndeterminate => {
  // Create a 2D array of class facilitator/user UIDs.
  const classFacilitatorIds = classesFilled.map(({ facilitators = [] }) =>
    facilitators.map(({ uid }) => uid),
  );

  // The checked facilitator UIDs are those that appear in ALL classes.
  const checkedFacilitatorIds = intersection(...classFacilitatorIds);

  // The indeterminate facilitator UIDs are those that appear in SOME but NOT
  // ALL classes.
  const uniqueClassFacilitatorIds = uniq(
    classFacilitatorIds.flatMap((item) => item),
  );

  const indeterminateFacilitatorIds = difference(
    uniqueClassFacilitatorIds,
    checkedFacilitatorIds,
  );

  const states: IdsOfCheckedIndeterminate = {
    checked: checkedFacilitatorIds,
    indeterminate: indeterminateFacilitatorIds,
  };

  return states;
};
