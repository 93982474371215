import keyBy from 'lodash/keyBy';
import { locales } from '../i18n';

import { CompositeMetricConfig, MetricItemConfig } from './types';

export const mathPilot22MathIdentityItemConfig: MetricItemConfig[] = [
  {
    label: 'math_id_good',
    likertN: 6,
    minGood: 5,
    prompts: {
      [locales.ENGLISH]: `I know I can be good at math.`,
      [locales.SPANISH]: `Sé que puedo ser bueno/a en matemáticas.`,
    },
  },
  {
    label: 'math_id_enjoy',
    likertN: 6,
    minGood: 5,
    prompts: {
      [locales.ENGLISH]: `I enjoy learning math.`,
      [locales.SPANISH]: `Disfruto aprender matematicas.`,
    },
  },
  {
    label: 'math_id_motivated',
    likertN: 6,
    minGood: 5,
    prompts: {
      [locales.ENGLISH]: `I am motivated to work hard in math.`,
      [locales.SPANISH]: `Estoy motivado/a para trabajar duro en matemáticas.`,
    },
  },
];

export const mathPilot22MathIdentityConfig: CompositeMetricConfig = {
  // NOTE: check the name of this metric as configured in the production
  // database. As of 2023-08-02 it's "Math Affinity" which might be unexpected.
  label: 'math-identity',
  items: mathPilot22MathIdentityItemConfig,
  itemIndex: keyBy(mathPilot22MathIdentityItemConfig, 'label'),
};
