import { participantAttributeConfig } from '@perts/config';

const getParticipantAttributeConfig = (attributePath: string) => {
  if (attributePath === 'all_participants') {
    return { name: 'All', shortName: 'all' };
  }

  const [attrLabel, valLabel] = attributePath.split('.');

  const valueConfig = participantAttributeConfig[attrLabel].values.find(
    (conf) => conf.label === valLabel,
  );

  return valueConfig;
};

export const getAttributeValueName = (attributePath: string) => {
  const valueConfig = getParticipantAttributeConfig(attributePath);

  if (!valueConfig) {
    return '';
  }

  return valueConfig.name;
};

export const getAttributeValueShortName = (attributePath: string) => {
  const valueConfig = getParticipantAttributeConfig(attributePath);

  if (!valueConfig) {
    return '';
  }
  return valueConfig.shortName || valueConfig.name;
};
