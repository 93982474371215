import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';

export const wellOrganizedClassItemConfig: MetricItemConfig[] = [
  {
    label: 'well_organized_class_1',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'I feel like this class is organized to help me do well.',

      es:
        'Siento que esta clase está organizada para ayudarme a que me ' +
        'vaya bien.',
    },
  },
  {
    label: 'well_organized_class_2',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'This teacher does a good job keeping track of and returning our work.',
      es:
        'Este maestro hace buen trabajo registrando y devolviendo ' +
        'nuestro trabajo.',
    },
  },
  {
    label: 'well_organized_class_3',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'It’s always clear what we’re supposed to be doing in this class.',
      es: 'Siempre está claro lo que debemos estar haciendo en esta clase.',
    },
  },
];

export const wellOrganizedClassConfig: CompositeMetricConfig = {
  label: 'well-organized-class',
  items: wellOrganizedClassItemConfig,
  itemIndex: keyBy(wellOrganizedClassItemConfig, 'label'),
};
