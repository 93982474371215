// Update owned_teams for any users who are being removed from classes.

import { uniq } from 'lodash';
import { User, UserToUpdate, updateUsers } from 'models';
import { UserClassGroupMap } from './types';

export const usersRemoveFromClasses = async (
  removes: UserClassGroupMap,
  users: User[],
) => {
  // We won't be removing users from groups, so we can ignore those.
  const removesWithoutGroups = uniq(
    removes.map((r) => ({
      userId: r.userId,
      classId: r.classId,
    })),
  );

  // Filter down to just the users found in the removes array.
  const usersToUpdate: UserToUpdate[] = users.filter((u) =>
    removesWithoutGroups.find((r) => u.uid === r.userId),
  );

  await updateUsers(usersToUpdate);

  return true;
};
