import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';

export const affirmingIdentitiesItemConfig: MetricItemConfig[] = [
  {
    label: 'affirming_identities_2',
    likertN: 6,
    minGood: 5,
    prompts: {
      en:
        'This teacher makes sure different backgrounds and perspectives are ' +
        'valued and supported.',
      es:
        'Este maestro asegura que las culturas y perspectivas diferentes son ' +
        'valoradas y apoyadas.',
    },
    // Further properties may go here, e.g. `likertN` or `likertReversed`
  },
  {
    label: 'affirming_identities_3',
    likertN: 6,
    minGood: 5,
    prompts: {
      en:
        'I see positive examples of people like me in the things we learn in ' +
        'this class.',
      es:
        'Veo ejemplos positivos de personas como yo en las cosas que ' +
        'aprendemos en esta clase.',
    },
  },
];

export const affirmingIdentitiesConfig: CompositeMetricConfig = {
  label: 'affirming-identities',
  items: affirmingIdentitiesItemConfig,
  itemIndex: keyBy(affirmingIdentitiesItemConfig, 'label'),
};
