import { useMemo } from 'react';
import { get } from 'lodash';

import {
  CompositeMetricConfig,
  getAttributeValueName,
  getAttributeValueShortName,
} from '@perts/config';
import type {
  ExperienceByAttribute,
  ExperienceResults,
  MetricSampleResults,
} from '@perts/model';
import { Col, HelpText, Row, Text } from '@perts/ui';

import { useTerms } from 'components/TermsContext';
import { helpArticles } from 'config';
import {
  getCumulativeChange,
  getIsEquityGap,
  getRecentCycleValue,
} from 'utils';

import { DISAGGREGATE_BY_ALL } from './constants';
import type { DemographicRow } from './types';
import { getAttrLabelsWithGaps } from './getAttrLabelsWithGaps';
import { getItemsWithGaps } from './getItemsWithGaps';
import { useStateFilterDisaggregateBy } from './useStateFilterDisaggregateBy';
import { DisaggregationTable } from './DisaggregationTable';
import { SelectFilterMulti } from './SelectFilterMulti';

type Props = {
  experienceData: ExperienceResults;
  metricConfig: CompositeMetricConfig;
  metricName: string;
  programLabel: string;
  sampleData: MetricSampleResults;
  sampleSizeTotal: number;
  initialDisaggregateBy?: string;
  initialShowBy?: string;
};

export const MetricExperience = ({
  experienceData,
  metricConfig,
  metricName,
  programLabel,
  sampleData,
  sampleSizeTotal,
  initialDisaggregateBy,
  initialShowBy,
}: Props) => {
  const terms = useTerms();
  const isFilterSelectMulti = true;
  const initialFilterState = {
    disaggregateBy: initialDisaggregateBy,
    showBy: initialShowBy,
  };
  const { disaggregateBy, setDisaggregateBy, showBy, setShowBy, applyToAll } =
    useStateFilterDisaggregateBy(isFilterSelectMulti, initialFilterState);

  const getAttributeSample = (itemLabel: string, attributePath: string) =>
    itemLabel === 'composite'
      ? get(sampleData, ['composite', attributePath], 0)
      : get(sampleData, ['by_item', itemLabel, attributePath], 0);

  const getItemRow = (
    label: string,
    name: string,
    sampleSize: number,
    byAttr: ExperienceByAttribute,
  ): DemographicRow => {
    const cycleValuesAll = byAttr.all_participants;
    const ratedPositively = getRecentCycleValue(cycleValuesAll);

    return {
      cumulativeChange: getCumulativeChange(cycleValuesAll),
      name,
      isComposite: label === 'composite', // metric title will be bold
      label,
      ratedPositively,
      sampleSize,
      sampleSizeTotal,
      byAttribute: Object.entries(byAttr).map(
        ([attributePath, cycleValues]) => {
          const [attributeLabel, attributeValue] = attributePath.split('.');
          const recent = getRecentCycleValue(cycleValues);

          return {
            attributeLabel,
            attributePath,
            attributeValue,
            attributeValueName: getAttributeValueName(attributePath),
            attributeValueShortName: getAttributeValueShortName(attributePath),
            cumulativeChange: getCumulativeChange(cycleValues),
            isEquityGap: getIsEquityGap(ratedPositively, recent),
            ratedPositively: recent,
            sampleSize: getAttributeSample(label, attributePath),
          };
        },
      ),
    };
  };

  // Start with the composite score.
  const compositeRow = getItemRow(
    'composite',
    metricName,
    get(sampleData, ['composite', DISAGGREGATE_BY_ALL], 0),
    experienceData.composite,
  );

  // Add rows for each item.
  const itemRows = Object.entries(experienceData.by_item).map(
    ([itemLabel, byAttribute]) =>
      getItemRow(
        itemLabel,
        // We don't have a "name" for items. Instead, display the literal
        // prompt associated with the item.
        get(
          metricConfig,
          ['itemIndex', itemLabel, 'prompts', 'en'],
          '(unknown item)',
        ),
        get(sampleData, ['by_item', itemLabel, DISAGGREGATE_BY_ALL], 0),
        byAttribute,
      ),
  );

  // Sort item rows according to config.
  const itemLabels = metricConfig.items.map((item) => item.label);
  itemRows.sort(
    (a, b) => itemLabels.indexOf(a.label) - itemLabels.indexOf(b.label),
  );

  const data: DemographicRow[] = useMemo(
    () =>
      showBy === 'metricOnly' ? [compositeRow] : [compositeRow, ...itemRows],
    [compositeRow, itemRows, showBy],
  );

  // Apply current filter state.
  // Note that the "All" subgroup should always be visible.
  const dataFiltered = useMemo(
    () =>
      data.map((row) => ({
        ...row,
        byAttribute: row.byAttribute.filter(({ attributeLabel }) =>
          [DISAGGREGATE_BY_ALL, disaggregateBy].includes(attributeLabel),
        ),
      })),
    [data, disaggregateBy],
  );

  const itemsWithGaps = getItemsWithGaps(itemRows);

  // This allows us to display correctly the equity gap indicators for the
  // simple/uncombined gender, race, etc., options in the select filter.
  const dataMetricOnly = [compositeRow];
  const attrLabelsWithGapsMetricOnly = getAttrLabelsWithGaps(dataMetricOnly);

  // This allows us to display correctly the equity gap indicators for the
  // combined question and gender, race, etc., options in the select filter.
  const dataCombined = [compositeRow, ...itemRows];
  const attrLabelsWithGapsCombined = getAttrLabelsWithGaps(dataCombined);

  return (
    <>
      <Row noPageBreakInside>
        <Col cols={6} vAlign="center">
          <Text as="h3" id="metric-experience-overview" includeMargin={false}>
            {metricName} by Subpopulation and/or Question
          </Text>
        </Col>
        <Col cols={6} vAlign="center" hAlign="flex-end">
          <HelpText articleId={helpArticles.resultsBySubpopulationGraph}>
            Tips for using this
          </HelpText>
        </Col>
      </Row>

      <Row noPageBreakInside>
        <Col cols={12}>
          <Row>
            <SelectFilterMulti
              programLabel={programLabel}
              showBy={showBy}
              setShowBy={setShowBy}
              disaggregateBy={disaggregateBy}
              setDisaggregateBy={setDisaggregateBy}
              applyToAll={applyToAll}
              questionsHaveGaps={itemsWithGaps.length > 0}
              attrLabelsWithGapsCombined={attrLabelsWithGapsCombined}
              attrLabelsWithGapsMetricOnly={attrLabelsWithGapsMetricOnly}
            />
          </Row>
        </Col>
      </Row>

      <DisaggregationTable
        data={dataFiltered}
        primaryHeader={terms.condition}
      />
    </>
  );
};
