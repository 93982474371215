import React, { useContext } from 'react';
import styled from 'styled-components/macro';

import { PortalType } from '@perts/model';

import { useQueryClient } from 'react-query';
import { message } from 'antd';
import pluralize from 'pluralize';
import { isEmpty } from 'lodash';
import {
  Class,
  invalidateGroupClasses,
  queryKeyClassesByGroup,
  updateClass,
} from 'models';
import {
  toGroupClasses,
  toGroupClassRoster,
  toGroupClassesEditTargetGroup,
  toGroupClassesRosterIDRule,
  toGroupClassesRosterLocking,
  toGroupClassesRosterSignOnType,
  toGroupClassesEditUsers,
  toGroupClassesEditGroups,
} from 'pages';
import TermsContext from 'components/TermsContext';

import {
  Card,
  Col,
  EditInPlaceForm,
  IconChevronRight,
  Row,
  Show,
  Text,
  IconButton,
  IconEnvelope,
  IconGoogle,
  IconIdBadge,
  IconRule,
  IconLock,
  IconTarget,
  IconUsergroup,
  IconBubbles,
} from '@perts/ui';
import { useOpenModal } from 'utils';
import getUserName from 'utils/getUserName';
import { ClassCardGroupContainer, ClassCardGroup } from './ClassCardGroup';
import { ClassCardFacilitatorContainer } from './ClassCardFacilitator';
import { CardButton } from 'components/CardButton';

type ClassCardProps = {
  // The Class to display.
  cls: Class;

  // The main/displayed Group that this Class belongs to.
  groupId: string;

  // The useToggle Toggle component.
  toggle: React.ReactNode;

  mayNavigateToRoster: boolean;

  mayEditNameInPlace: boolean;

  mayEditTargetGroup: boolean;

  checked?: boolean;

  // Used to define a custom aria-role to the Card
  role?: string;
};

type PortalTypeIcons = {
  [portalType in PortalType]: React.ReactNode;
};

const portalTypeIcons: PortalTypeIcons = {
  email: <IconEnvelope />,
  google: <IconGoogle />,
  student_id: <IconIdBadge />,
};

const IconButtonsContainer = styled.div`
  display: flex;
  > * {
    margin: 0 5px 0 0;
  }
`;

export const ClassCard = ({
  groupId = '',
  cls,
  toggle,
  mayNavigateToRoster,
  mayEditNameInPlace,
  mayEditTargetGroup,
  checked,
  ...rest
}: ClassCardProps) => {
  const terms = useContext(TermsContext);
  const queryClient = useQueryClient();
  const openModal = useOpenModal();

  // Classes should have at least a single facilitator. But handle the case
  // where ClassCard is rendered before the facilitators have finished loading.
  const { facilitators = [] } = cls;

  // Classes should have at least a single Group. But handle the case
  // where ClassCard is rendered before the Groups have finished loading.
  const { groups = [] } = cls;

  const queryInvalidateFn = () => invalidateGroupClasses(queryClient, groupId);

  const uniqComparator = (previous, current) => previous.uid === current.uid;

  const handleAction = (route: string) => {
    openModal(route, {
      checked: {
        [cls.uid]: true,
      },
    });
  };

  return (
    <Card toggle={toggle} checked={checked} {...rest}>
      <Card.Title>
        <Row alignItems="center">
          <Col cols={6} colsSm={12}>
            <Show when={mayEditNameInPlace}>
              <EditInPlaceForm<Class>
                name="name"
                label={`Edit in place ${cls.name}`}
                value={cls}
                emptyValueMessage="No name set"
                queryKey={queryKeyClassesByGroup(groupId)}
                queryUpdateFn={updateClass}
                queryInvalidateFn={queryInvalidateFn}
                uniqComparator={uniqComparator}
                successFn={() =>
                  message.success(
                    `Successfully updated the ${terms.class.toLowerCase()}'s name.`,
                  )
                }
                errorFn={() =>
                  message.error(
                    `There was an error updating that ${terms.class.toLowerCase()}'s name.`,
                  )
                }
              />
            </Show>
            <Show when={!mayEditNameInPlace}>
              <span data-testid="cls-static-name">{cls.name}</span>
            </Show>
          </Col>
          <Col cols={6} colsSm={12}>
            <Row>
              <Col>
                <IconButtonsContainer>
                  <IconButton
                    aria-label="Portal type"
                    icon={portalTypeIcons[cls.portal_type]}
                    state="active"
                    onClick={() =>
                      handleAction(toGroupClassesRosterSignOnType(groupId))
                    }
                  />

                  <IconButton
                    aria-label="Sign-on rule"
                    icon={<IconRule />}
                    state={
                      cls.participant_ending || cls.participant_pattern
                        ? 'active'
                        : 'inactive'
                    }
                    onClick={() =>
                      handleAction(toGroupClassesRosterIDRule(groupId))
                    }
                  />

                  <IconButton
                    aria-label="Roster locking"
                    icon={<IconLock />}
                    state={cls.roster_locked ? 'active' : 'inactive'}
                    onClick={() =>
                      handleAction(toGroupClassesRosterLocking(groupId))
                    }
                  />

                  <Show when={mayEditTargetGroup}>
                    <IconButton
                      aria-label="Focal group"
                      icon={<IconTarget />}
                      state={cls.target_group_name ? 'active' : 'inactive'}
                      onClick={() =>
                        handleAction(toGroupClassesEditTargetGroup(groupId))
                      }
                    />
                  </Show>
                </IconButtonsContainer>
              </Col>
              <Col shrink>
                <Show when={mayNavigateToRoster}>
                  <CardButton
                    to={toGroupClassRoster(groupId, cls.short_uid, 'roster')}
                    color="text"
                    iconRight={<IconChevronRight />}
                  >
                    {`${pluralize(
                      terms.participant,
                      cls.num_students || 0,
                      true,
                    )}`}
                  </CardButton>
                </Show>
                <Show when={!mayNavigateToRoster}>
                  <Text>
                    {`${pluralize(terms.participant, cls.num_students, true)}`}
                  </Text>
                </Show>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Title>

      <Card.Content>
        <Row alignItems="center">
          <Col cols={6} colsSm={12} vAlign="center">
            <IconButton
              aria-label={terms.classManagers}
              icon={<IconUsergroup />}
              state="stateless"
              onClick={() => handleAction(toGroupClassesEditUsers(groupId))}
            />
            <ClassCardFacilitatorContainer>
              {!isEmpty(facilitators) &&
                facilitators.map(getUserName).join(', ')}
            </ClassCardFacilitatorContainer>
          </Col>

          <Col cols={6} colsMd={6} colsSm={12} vAlign="center">
            <IconButton
              aria-label={terms.groups}
              icon={<IconBubbles />}
              state="stateless"
              onClick={() => handleAction(toGroupClassesEditGroups(groupId))}
            />
            <ClassCardGroupContainer>
              {groups.length === 0
                ? null
                : groups
                    .map(
                      (group): React.ReactNode => (
                        <ClassCardGroup
                          key={group.uid}
                          to={
                            group.uid === groupId
                              ? undefined
                              : toGroupClasses(group.uid)
                          }
                          group={group}
                          truncateWidth={250}
                        />
                      ),
                    )
                    .reduce((prev, curr) => [prev, ', ', curr])}
            </ClassCardGroupContainer>
          </Col>
        </Row>
      </Card.Content>
    </Card>
  );
};
