// https://docs.google.com/spreadsheets/d/1EKKzZjI-eCtekB_S_SsNnBQpGEKVIKueZIkBD6W8QJc

import keyBy from 'lodash/keyBy';

import { locales } from '../i18n';
import { CompositeMetricConfig, MetricItemConfig } from './types';

const options = {
  [locales.ENGLISH]: [
    {
      label: 'Never',
      value: 5,
    },
    {
      label: 'Hardly Ever',
      value: 4,
    },

    {
      label: 'Sometimes',
      value: 3,
    },
    {
      label: 'Frequently',
      value: 2,
    },
    {
      label: 'Always',
      value: 1,
    },
  ],
  [locales.SPANISH]: [
    {
      label: 'Nunca',
      value: 5,
    },
    {
      label: 'Casi nunca',
      value: 4,
    },
    {
      label: 'A veces',
      value: 3,
    },
    {
      label: 'Con frequencia',
      value: 2,
    },
    {
      label: 'Siempre',
      value: 1,
    },
  ],
};

export const belongingCertaintyItemConfig: MetricItemConfig[] = [
  {
    label: 'belonging_certainty',
    prompts: {
      en: `When thinking about this class, how often, if ever, do you wonder ‘Maybe I don’t belong here?’`,
      // Note: ascend does not currently support es locale.
      es: `Al pensar en esta clase, ¿con qué frecuencia, si es que alguna vez, se pregunta 'Tal vez no pertenezco aquí?'`,
    },
    options,
    minGood: 4,
  },
];

export const belongingCertaintyConfig: CompositeMetricConfig = {
  label: 'belonging-certainty',
  items: belongingCertaintyItemConfig,
  itemIndex: keyBy(belongingCertaintyItemConfig, 'label'),
};
