import { InferType } from 'yup';

import {
  defaultScheduleSchema,
  metricLinkSchema,
  reportSettingsSchema,
} from './schemas';

export enum PortalType {
  GOOGLE = 'google',
  EMAIL = 'email',
  STUDENT_ID = 'student_id',
}

export enum SignInProvider {
  GOOGLE = 'google.com',
  PASSWORD = 'password',
}

export enum UserType {
  USER = 'user',
  SUPER_ADMIN = 'super_admin',
}

export enum MetricLinkType {
  READING = 'reading',
  IMAGE = 'img',
}

// See `AsscType` in legacy/triton/app/model/user_association.py
export enum UserAssociationType {
  MANAGED_ORGANIZATION = 'managed_organization',
  OWNED_NETWORK = 'owned_network',
  OWNED_ORGANIZATION = 'owned_organization',
  OWNED_TEAM = 'owned_team',
  PINNED_ORGANIZATION = 'pinned_organization',
}

export enum UserAssociationAttribute {
  MANAGED_ORGANIZATIONS = 'managed_organizations',
  OWNED_NETWORKS = 'owned_networks',
  OWNED_ORGANIZATIONS = 'owned_organizations',
  OWNED_TEAMS = 'owned_teams',
  PINNED_ORGANIZATIONS = 'pinned_organizations',
}

export type DefaultSchedule = InferType<typeof defaultScheduleSchema>;

export type MetricLink = InferType<typeof metricLinkSchema>;

export type ReportSettings = InferType<typeof reportSettingsSchema>;
