import keyBy from 'lodash/keyBy';
import { locales } from '../i18n';

import { CompositeMetricConfig, MetricItemConfig } from './types';
import { options } from './answerOptions/HowOften5Options';

export const mathPilot22CollaborativeLearningItemConfig: MetricItemConfig[] = [
  {
    label: 'collab_ideas',
    minGood: 4,
    prompts: {
      [locales.ENGLISH]: `I learn from other studentsʼ math ideas.`,
      [locales.SPANISH]: `Aprendo de las ideas matemáticas de otros estudiantes.`,
    },
    options,
  },
  {
    label: 'collab_share',
    minGood: 4,
    prompts: {
      [locales.ENGLISH]: `I get to share how I worked on a math problem with my classmates.`,
      [locales.SPANISH]: `Puedo compartir cómo trabajé en un problema de matemáticas con mis compañeros de clase.`,
    },
    options,
  },
];

export const mathPilot22CollaborativeLearningConfig: CompositeMetricConfig = {
  label: 'collaborative-learning',
  items: mathPilot22CollaborativeLearningItemConfig,
  itemIndex: keyBy(mathPilot22CollaborativeLearningItemConfig, 'label'),
};
