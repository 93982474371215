import { Class } from 'models';
import { FilterTokenOption } from 'utils/useFilterField';

export const filterTokenOptions: FilterTokenOption[] = [
  { token: 'type:email', filter: (cls: Class) => cls.portal_type === 'email' },
  {
    token: 'type:google',
    filter: (cls: Class) => cls.portal_type === 'google',
  },
  {
    token: 'type:id',
    filter: (cls: Class) => cls.portal_type === 'student_id',
  },
  {
    token: 'rule:on',
    filter: (cls: Class) => Boolean(cls.participant_pattern),
  },
  {
    token: 'rule:off',
    filter: (cls: Class) => !Boolean(cls.participant_pattern),
  },
  { token: 'locking:on', filter: (cls: Class) => cls.roster_locked },
  { token: 'locking:off', filter: (cls: Class) => !cls.roster_locked },
  {
    token: 'focalgroup:on',
    filter: (cls: Class) => Boolean(cls.target_group_name),
  },
  {
    token: 'focalgroup:off',
    filter: (cls: Class) => !Boolean(cls.target_group_name),
  },
];
