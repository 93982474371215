import { useContext } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { message } from 'antd';

import {
  Class,
  Group,
  Program,
  User,
  classesAddToGroup,
  invalidateGroupClasses,
  invalidateUsersFromGroup,
  queryKeyClassesByGroup,
  selectUserIsAdmin,
  selectUserIsGroupManager,
  useCurrentUserQuery,
  useGroupGetByParams,
  useProgramsQuery,
  useUsersQueryByParams,
} from 'models';
import { useParams } from 'pages';
import { useCloseModal } from 'utils';
import getUserName from 'utils/getUserName';

import { ClassesAddForm, ClassAddFormValues } from './ClassesAddForm';
import Loading from 'components/Loading';
import TermsContext from 'components/TermsContext';
import { ErrorMessageBox } from 'components/ErrorMessageBox';
import { getMessageFromErrors } from '@perts/util';

type ClassesAddMutationValues = ClassAddFormValues & {
  group: Group;
  programs: Program[];
  users: User[];
};

// eslint-disable-next-line complexity
export const ClassesAdd = () => {
  const { groupId } = useParams();
  const queryClient = useQueryClient();
  const queryKey = queryKeyClassesByGroup(groupId);
  const terms = useContext(TermsContext);

  // Close the modal.
  const closeModal = useCloseModal({
    ignoreEmptyState: true,
  });

  // Mutation: Add class to group.
  // https://react-query.tanstack.com/guides/mutations
  const mutation = useMutation(
    // Transform form values into a shape that can be sent to the api function.
    // The params group, programs and users are received as parameters
    // to ensure that they exist and we prevent not use optional chaining (?)
    async ({
      name,
      subjectArea,
      facilitatorId,
      group,
      programs,
      users,
    }: ClassesAddMutationValues) => {
      // On additions, we need to perform the optimisitic update inside the
      // mutation function because we need the return object from the server.
      // This should be okay, because the form appears in a dialog, and won't
      // return the user back to the list until the server has responded.

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queryKey);

      // Snapshot the previous value.
      const previous = queryClient.getQueryData<Class[]>(queryKey);

      // Determine group program.
      const program = programs.find((p) => p.uid === group.program_id);
      const facilitator = users.find((u) => u.uid === facilitatorId);

      // If there's no program or facilitator, return early.
      if (!program || !facilitator) {
        return { previous };
      }

      // API call.
      const cls = await classesAddToGroup(
        name,
        subjectArea,
        facilitator,
        program,
        group,
      );

      // Optimistically update to the new value.
      const previousClasses = previous || [];
      queryClient.setQueryData<Class[]>(queryKey, [...previousClasses, cls]);

      // Return a context object with the snapshot value.
      return { previous };
    },
    {
      // Handle successful mutation.
      onSuccess: (data, variables, context) => {
        message.success(
          `Successfully created the ${terms.class.toLowerCase()}.`,
        );

        // Invalidate classes and users to force a refetch of current classes.
        invalidateGroupClasses(queryClient, groupId);
        invalidateUsersFromGroup(queryClient, groupId);
      },
      // If the mutation fails, use the context returned to roll back.
      onError: (err, values, context) => {
        // Not sure why in some cases TypeScript doesn't understand what's
        // coming back on context.
        type Context = {
          previous: Class[] | undefined;
        };

        const typedContext = context as Context;

        if (typedContext?.previous) {
          queryClient.setQueryData<Class[]>(queryKey, typedContext.previous);
        }
      },
    },
  );

  // Query for Group.
  const {
    isLoading: groupIsLoading,
    isError: groupIsError,
    data: group,
    error: groupError,
  } = useGroupGetByParams();

  // Query for Programs.
  const {
    isLoading: programsIsLoading,
    isError: programsIsError,
    data: programs = [],
    error: programsError,
  } = useProgramsQuery();

  // Query for Users of Group.
  const {
    isLoading: usersIsLoading,
    data: users = [],
    isError: usersIsError,
    error: usersError,
  } = useUsersQueryByParams();

  const { data: currentUser, isLoading: currentUserIsLoading } =
    useCurrentUserQuery();

  // Display loading when data is loading.
  const isLoading =
    groupIsLoading ||
    programsIsLoading ||
    usersIsLoading ||
    currentUserIsLoading;

  if (isLoading) {
    return <Loading />;
  }

  // Display errors.
  if (groupIsError || programsIsError || usersIsError) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([usersError, programsError, groupError])}
      </ErrorMessageBox>
    );
  }

  if (!group || !programs || !users || !currentUser) {
    // TODO Define functionality to handle undefined or empty data
    return null;
  }

  // Email is shown as an alternative if the facilitator does not have a name
  const usersFilled: User[] = users.map((user) => ({
    ...user,
    name: getUserName(user),
  }));

  // https://stackoverflow.com/questions/65760158/react-query-mutation-typescript
  // Formik onSubmit handler
  const onSubmit = async (values: ClassAddFormValues) => {
    await mutation.mutateAsync({
      ...values,
      group,
      programs,
      users,
    });
  };

  const maySelectFacilitator =
    selectUserIsAdmin(currentUser) ||
    selectUserIsGroupManager(currentUser, groupId);

  return (
    <ClassesAddForm
      close={closeModal}
      currentUser={currentUser}
      facilitators={usersFilled}
      maySelectFacilitator={maySelectFacilitator}
      onSubmit={onSubmit}
      programId={group.program_id}
    />
  );
};
