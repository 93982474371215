import { useState } from 'react';

import { useGroupsQuery, useNetworksQuery } from 'models';
import { toGroup, toNetwork } from 'pages';

import { Card, Col, Row, Show, Link } from '@perts/ui';
import FakeLink from 'components/FakeLink';
import { useTerms } from 'components/TermsContext';

import { ImpersonateUser } from './ImpersonateUser';

export const SearchUserCard = ({ user }) => {
  // Allows loading messages to be hidden until the user has interacted.
  const [loadUser, setLoadUser] = useState(false);
  const terms = useTerms();

  const {
    data: groups = [],
    isIdle: groupsIsIdle,
    isLoading: groupsIsLoading,
  } = useGroupsQuery({ userId: user.uid }, { enabled: loadUser });

  const {
    data: networks = [],
    isIdle: networksIsIdle,
    isLoading: networksIsLoading,
  } = useNetworksQuery({ userId: user.uid }, { enabled: loadUser });

  const isIdle = groupsIsIdle || networksIsIdle;
  const isLoading = groupsIsLoading || networksIsLoading;

  return (
    <Card key={user.uid}>
      <Card.Title>
        <Row>
          <Col>
            {user.name}{' '}
            <span>
              &lt;<a href="mailto:">{user.email}</a>&gt;
            </span>
          </Col>

          <Col shrink>
            <ImpersonateUser uid={user.uid} />
          </Col>

          <Col shrink>
            {isIdle && (
              <FakeLink onClick={() => setLoadUser(true)}>Load</FakeLink>
            )}

            {isLoading && <span>Loading...</span>}
          </Col>
        </Row>
      </Card.Title>

      {!isIdle && !isLoading && (
        <Card.Content>
          <p>{terms.networks}:</p>
          <ul>
            {networks.map((network) => (
              <li key={network.uid}>
                {network.name} <Link to={toNetwork(network.uid)}>View</Link>
              </li>
            ))}
            <Show when={networks.length === 0}>
              <li>
                <em>none</em>
              </li>
            </Show>
          </ul>
          <p>{terms.groups}:</p>
          <ul>
            {groups.map((group) => (
              <li key={group.uid}>
                {group.name} <Link to={toGroup(group.uid)}>View</Link>
              </li>
            ))}
            <Show when={groups.length === 0}>
              <li>
                <em>none</em>
              </li>
            </Show>
          </ul>
        </Card.Content>
      )}
    </Card>
  );
};
