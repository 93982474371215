import { Group, GroupMember, selectUserGroupIsPinned } from 'models';
import { useParams } from 'pages';
import useToggles from 'utils/useToggles';

import { MemberCard } from './MemberCard';
import { MembersActionbar } from './MembersActionbar';
import { MembersGuideBox } from './MembersGuideBox';

import { Show } from '@perts/ui';
import { EmptyList } from 'components/EmptyList';
import { useTerms } from 'components/TermsContext';
import { usePermissions } from 'utils';
import useFilterField from 'utils/useFilterField';
import { useClientPagination } from 'utils/useClientPagination';

export type MembersRenderProps = {
  group: Group;
  members: GroupMember[];
  SortByComponent?: React.FC<any>;
};

export const MembersRender = ({
  group,
  members,
  SortByComponent = () => null,
}: MembersRenderProps) => {
  const { groupId } = useParams();
  const {
    mayGroupMemberEditName,
    mayGroupMemberEditPin,
    mayGroupMemberPromote,
    mayGroupMemberRemove,
    mayGroupMemberSendInvite,
  } = usePermissions();
  const terms = useTerms();
  const { filteredItems, FilterField } = useFilterField(
    members,
    ['name', 'email'],
    {
      label: `Filter by ${terms.groupMember.toLowerCase()} name or email`,
    },
  );

  const { currentPageItems, Pagination } = useClientPagination({
    data: filteredItems,
  });

  const {
    ControlToggle,
    Toggle,
    checked,
    selected,
    toggleAll,
    toggleAllFilter,
  } = useToggles(members, 'uid', {
    pageItems: currentPageItems,
    filteredItems,
  });

  return (
    <>
      <MembersActionbar
        ControlToggle={ControlToggle}
        Pagination={Pagination}
        archived={group.archived}
        checked={checked}
        filterComponent={FilterField}
        filteredItems={filteredItems}
        members={members}
        selected={selected}
        toggleAll={toggleAll}
        toggleAllFilter={toggleAllFilter}
        SortByComponent={SortByComponent}
      />

      <Show when={members.length === 0}>
        <EmptyList />
      </Show>

      {currentPageItems.map((member) => {
        const isPinned = selectUserGroupIsPinned(member, groupId);

        const toggle =
          mayGroupMemberPromote || mayGroupMemberRemove ? (
            <Toggle id={member.uid} label="" aria-label={member.email} />
          ) : null;

        return (
          <MemberCard
            key={member.uid}
            isPinned={isPinned}
            mayGroupMemberEditName={mayGroupMemberEditName}
            mayGroupMemberEditPin={mayGroupMemberEditPin(member)}
            mayGroupMemberSendInvite={mayGroupMemberSendInvite}
            mayGroupMemberPromote={mayGroupMemberPromote}
            member={member}
            toggle={toggle}
            checked={checked}
          />
        );
      })}

      <MembersGuideBox />
    </>
  );
};
