import { useMemo, useState } from 'react';
import {
  useFilters,
  usePagination as usePaginationHook,
  useTable,
} from 'react-table';
import styled from 'styled-components/macro';

import { DataOverTime } from '@perts/model';
import { Col, Link } from '@perts/ui';

import {
  ReactTable,
  ReactTableFooter,
  ReactTablePagination,
  ReactTableTd,
  ReactTableTh,
  ReactTableTr,
  ReactTableWrap,
} from 'components';
import { useTerms } from 'components/TermsContext';
import { DEFAULT_PAGE_SIZE, FIDELITY_THRESHOLD } from 'config';
import { toGroupReport } from 'pages';
import { getCumulativeChange, getRecentCycleValue } from 'utils';
import {
  TdChangeFromStart,
  TdFidelityRatedPositively,
  // eslint-disable-next-line max-len
} from '../ExperienceDisaggregation/DisaggregationTable/DisaggregationTableStyled';
import { DisaggregationTableFilter } from '../ExperienceDisaggregation/DisaggregationTable/DisaggregationTableFilter';
import { DisaggregationTableGapCheckbox } from '../ExperienceDisaggregation/DisaggregationTable/DisaggregationTableGapCheckbox';
import FidelityChildTableRow from '../FidelityChildTableRow';

const Strong = styled.strong`
  font-weight: bold;

  a {
    font-weight: bold;
  }
`;

type FidelityChildRow = {
  groupId: string;
  name: string;
  makeBetterData: DataOverTime;
};

type Props = {
  data: FidelityChildRow[];
};

export const FidelityChildTable = ({ data }: Props) => {
  const terms = useTerms();

  const [showLowOnly, setShowLowOnly] = useState(false);

  const threshold = FIDELITY_THRESHOLD / 100;

  const tableData = useMemo(
    () =>
      data
        .map((row) => ({
          name: row.name,
          link: toGroupReport(row.groupId),
          ratedPositively: getRecentCycleValue(row.makeBetterData),
          cumulativeChange: getCumulativeChange(row.makeBetterData),
        }))
        .filter((row) =>
          showLowOnly ? (row.ratedPositively || 1) < threshold : true,
        ),
    [data, showLowOnly, threshold],
  );

  const columns = useMemo(
    () => [
      {
        Header: terms.group,
        accessor: 'name',
        align: 'left',
      },
      {
        Header: 'Rated Positively on Last Survey',
        accessor: 'ratedPositively',
        align: 'center',
        disableFilters: true,
      },
      {
        Header: '± From First Survey',
        accessor: 'cumulativeChange',
        align: 'center',
        disableFilters: true,
      },
    ],
    [terms.group],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // from useFilter
    rows: rowsFiltered,
    // pagination
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: {
        pageSize: DEFAULT_PAGE_SIZE,
      },
      autoResetFilters: false,
    },
    useFilters,
    // The usePagination plugin hook must be placed after useFilters.
    usePaginationHook,
  );

  return (
    <>
      <FidelityChildTableRow>
        <Col cols={8}>
          <DisaggregationTableFilter
            by={terms.group}
            column={headerGroups[0].headers[0]}
            setFilter={setFilter}
          />
        </Col>
        <Col cols={4} vAlign="flex-end" hAlign="flex-end">
          <DisaggregationTableGapCheckbox
            checked={showLowOnly}
            label={`Filter to ${terms.groups.toLowerCase()} < ${FIDELITY_THRESHOLD}%`}
            onChange={() => setShowLowOnly(!showLowOnly)}
          />
        </Col>
      </FidelityChildTableRow>

      <ReactTableWrap>
        <ReactTable {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <ReactTableTh
                    {...column.getHeaderProps([{ align: column.align }])}
                  >
                    {column.render('Header')}
                  </ReactTableTh>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);

              return (
                <ReactTableTr
                  {...row.getRowProps()}
                  isFirstGroupRow={true}
                  isLastGroupRow={true}
                >
                  <ReactTableTd cell={row.cells[0]}>
                    <Strong>
                      <Link to={row.cells[0].row.original.link}>
                        {row.cells[0].row.original.name}
                      </Link>
                    </Strong>
                  </ReactTableTd>
                  <TdFidelityRatedPositively cell={row.cells[1]} />
                  <TdChangeFromStart cell={row.cells[2]} />
                </ReactTableTr>
              );
            })}
          </tbody>
        </ReactTable>

        <ReactTableFooter>
          <ReactTablePagination
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            gotoPage={gotoPage}
            nextPage={nextPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            previousPage={previousPage}
            setPageSize={setPageSize}
            totalRows={rowsFiltered.length}
          />
        </ReactTableFooter>
      </ReactTableWrap>
    </>
  );
};
