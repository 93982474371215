import keyBy from 'lodash/keyBy';

import { CompositeMetricConfig, MetricItemConfig } from './types';

export const trustFairnessItemConfig: MetricItemConfig[] = [
  {
    label: 'trust_respect',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'This instructor treats me with respect.',
    },
  },
  {
    label: 'trust_fair',
    likertN: 6,
    minGood: 5,
    prompts: {
      en: 'I trust this instructor to treat me fairly.',
    },
  },
];

export const trustFairnessConfig: CompositeMetricConfig = {
  label: 'trust-fairness',
  items: trustFairnessItemConfig,
  itemIndex: keyBy(trustFairnessItemConfig, 'label'),
};
