import React, { useState } from 'react';

import type { ProgramMetricConfig } from '@perts/config';
import {
  ExperienceResults,
  MetricSampleResults,
  OpenResponseResults,
  OrganizationExperienceResults,
} from '@perts/model';
import {
  Card,
  Col,
  HelpText,
  Link,
  Row,
  Show,
  Switch,
  Text,
  getMetricLogo,
  useBroadcast,
} from '@perts/ui';

import { channelOpenResponses, helpArticles } from 'config';
import { Group, Metric, Network, selectMetricReadingLink } from 'models';
import { MetricExperience, MetricGroupExperience } from '../';
import {
  MetricTimeline,
  NetworkChildBySurvey,
  OpenResponseItemList,
} from '../components';

import { getCumulativeChange } from 'utils';
import { MetricSectionHighlight } from './MetricSectionHighlight';
import { useStateFilterDisaggregateBy } from '../ExperienceDisaggregation/useStateFilterDisaggregateBy';

type Props = {
  experienceData: ExperienceResults;
  groups?: Group[];
  metric: Metric;
  network?: Network;
  openResponseResults?: OpenResponseResults;
  programMetricConfig: ProgramMetricConfig;
  programLabel: string;
  resultsByGroup?: { [groupId: string]: OrganizationExperienceResults };
  sampleData: MetricSampleResults;
  sampleSizeTotal: number;
};

// eslint-disable-next-line complexity
export const MetricSection = ({
  experienceData,
  groups,
  metric,
  network,
  openResponseResults,
  programMetricConfig,
  programLabel,
  resultsByGroup,
  sampleData,
  sampleSizeTotal,
}: Props) => {
  const [networkMetricExperienceOn, setNetworkMetricExperience] =
    useState(false);

  const [showOpenResponses, setShowOpenResponses] = useState(true);

  const { listen: listenOpenResponses } = useBroadcast(channelOpenResponses);

  listenOpenResponses((shouldShow) => {
    if (shouldShow !== showOpenResponses) {
      setShowOpenResponses(shouldShow);
    }
  });

  const isFilterSelectMulti = true;
  const { disaggregateBy: initialDisaggregateBy, showBy: initialShowBy } =
    useStateFilterDisaggregateBy(isFilterSelectMulti);

  const metricName = metric.name;
  const metricLabel = metric.label;
  const MetricLogo = getMetricLogo(metric.label);

  const overallData = experienceData.composite.all_participants;

  const timePointsWithData = overallData.filter((x) => x !== null).length;

  // Default to 0 in case change is NaN.
  const cumulativeChange = getCumulativeChange(overallData) || 0;

  return (
    <section id={`${metricLabel}-data`}>
      <Card>
        <Card.Title>
          <Row>
            <Col shrink={false} hAlign="flex-start">
              <Text as="h2">
                <MetricLogo />
                {metricName}
              </Text>
            </Col>
          </Row>
        </Card.Title>
        <Card.Content>
          <p>
            {metric.description}{' '}
            <Link to={selectMetricReadingLink(metric)}>Learn strategies</Link>
          </p>

          {/* <GROUP and CLASS reports> */}

          <MetricSectionHighlight
            name={metricName}
            cumulativeChange={cumulativeChange}
          />

          {/* </GROUP and CLASS reports> */}

          {/* <NETWORK reports> */}

          {network && groups && resultsByGroup && (
            <MetricGroupExperience
              groups={groups}
              metricLabel={metricLabel}
              metricName={metricName}
              programLabel={programLabel}
              resultsByGroup={resultsByGroup}
              sampleSizeTotal={sampleSizeTotal}
            />
          )}

          {/* </NETWORK reports> */}

          <Show when={timePointsWithData > 1}>
            <Row noPageBreakInside>
              <Col shrink={false} cols={6}>
                <Text as="h3">{metricName} by Survey</Text>
              </Col>
              <Col shrink={false} cols={6} vAlign="center" hAlign="flex-end">
                <HelpText
                  articleId={helpArticles.studentExperienceBySurveyGraph}
                >
                  Tips for using this
                </HelpText>
              </Col>
            </Row>

            {/* Learning condition timeline graph */}
            <Row>
              <Col shrink={false}>
                {/* <GROUP and CLASS reports> */}
                <Show when={!network}>
                  <MetricTimeline
                    metricLabel={metricLabel}
                    metricName={metricName}
                    dataOverall={overallData}
                    dataByItem={experienceData.by_item}
                    programMetricConfig={programMetricConfig}
                  />
                </Show>
                {/* </GROUP and CLASS reports> */}

                {/* <NETWORK reports> */}
                {network && resultsByGroup && groups && (
                  <NetworkChildBySurvey
                    networkOverallData={overallData}
                    organizationData={resultsByGroup}
                    groups={groups}
                    metricLabel={metricLabel}
                  />
                )}
                {/* </NETWORK reports> */}
              </Col>
            </Row>
          </Show>

          {/* Learning condition bar graph (subpopulation) */}
          <Show when={Boolean(network)}>
            <Row>
              <Col shrink={false}>
                <Switch
                  id={`${metricLabel}Switch`}
                  label={`Show ${metricName} by Subpopulation and/or Question`}
                  checked={networkMetricExperienceOn}
                  onChange={() =>
                    setNetworkMetricExperience(!networkMetricExperienceOn)
                  }
                />
              </Col>
            </Row>
          </Show>
          <Show when={!network || networkMetricExperienceOn}>
            <MetricExperience
              experienceData={experienceData}
              metricConfig={programMetricConfig.metricIndex[metric.label]}
              metricName={metric.name}
              programLabel={programLabel}
              sampleData={sampleData}
              sampleSizeTotal={sampleSizeTotal}
              initialDisaggregateBy={initialDisaggregateBy}
              initialShowBy={initialShowBy}
            />
          </Show>

          {/* <CLASS reports> */}

          {openResponseResults && (
            <>
              <Text as="h3">Open-Ended Responses</Text>

              <Show when={showOpenResponses}>
                <Show when={Object.keys(openResponseResults).length === 0}>
                  <p>
                    <em>No responses yet.</em>
                  </p>
                </Show>
                <Show when={Object.keys(openResponseResults).length > 0}>
                  <OpenResponseItemList
                    metricLabel={metric.label}
                    programMetricConfig={programMetricConfig}
                    responsesByItem={openResponseResults}
                  />
                </Show>
              </Show>
              <Show when={!showOpenResponses}>
                <p>Open-ended responses are hidden.</p>
              </Show>
            </>
          )}

          {/* </CLASS reports> */}
        </Card.Content>
      </Card>
    </section>
  );
};
