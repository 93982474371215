import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import pluralize from 'pluralize';
import { Button, HelpText, Modal, useFormError, Row, Col } from '@perts/ui';
import { uniqWith } from 'lodash';

import { helpArticles } from 'config';
import { User, selectUserIsGroupManager } from 'models';
import { useParams } from 'pages';

import { useTerms } from 'components/TermsContext';
import { MembersManagerFormSwitch } from './MembersManagerFormSwitch';

export const uniqComparator = (manager1: User, manager2: User) =>
  manager1.uid === manager2.uid;

const ValidationSchema = Yup.object().shape({
  isManager: Yup.boolean(),
  isManagerIndeterminate: Yup.boolean(),
});

export const MembersManagerForm = ({
  close,
  allMembers,
  checkedMembers,
  onSubmit,
}) => {
  const [FormError, showFormError] = useFormError();
  const { groupId } = useParams();
  const terms = useTerms();

  const isManagerForAllMembers = checkedMembers.every((member: User) =>
    selectUserIsGroupManager(member, groupId),
  );

  const isManagerForSomeMembers = checkedMembers.some((member: User) =>
    selectUserIsGroupManager(member, groupId),
  );

  const switchOn = (member: User): User => ({
    ...member,
    managed_organizations: [...member.managed_organizations, groupId],
  });

  const switchOff = (member: User): User => ({
    ...member,
    managed_organizations: member.managed_organizations.filter(
      (managedOrgId) => managedOrgId !== groupId,
    ),
  });

  const getUpdatedMembers = ({ isManager, members }) => {
    const updatedMembers = members.map((member) => {
      const updatedMember = isManager
        ? // Set member as manager.
          switchOn(member)
        : // Set member as not manager.
          switchOff(member);

      return updatedMember;
    });

    return updatedMembers;
  };

  const validate = ({ isManager }) => {
    const updatedMembers = getUpdatedMembers({
      isManager,
      members: checkedMembers,
    });

    const errors: any = {};

    const allItems = uniqWith(
      [...updatedMembers, ...allMembers],
      uniqComparator,
    );

    const zeroManagers = allItems.every(
      (user) => !selectUserIsGroupManager(user, groupId),
    );

    if (zeroManagers) {
      const msg = `There must be at least one ${terms.groupManager}.`;
      errors.isManager = msg;
    }

    return errors;
  };

  const initialValues = {
    isManager: isManagerForAllMembers,
    isManagerIndeterminate: isManagerForSomeMembers && !isManagerForAllMembers,
  };

  return (
    <Modal close={close}>
      <Modal.Title className="center">Edit {terms.groupManagers}</Modal.Title>

      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        validate={validate}
        onSubmit={async ({ isManager }) => {
          try {
            const updatedMembers = getUpdatedMembers({
              isManager,
              members: checkedMembers,
            });

            // Clear existing form error.
            showFormError(false);

            // Perform form onSubmit.
            await onSubmit(updatedMembers);

            // Close modal on successful form onSubmit.
            close();
          } catch (error) {
            // Display form error.
            showFormError(true);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <MembersManagerFormSwitch />

            <Row>
              <Col hAlign="flex-end">
                <HelpText articleId={helpArticles.whatIsAMember}>
                  What are {terms.groupManagers.toLowerCase()}?
                </HelpText>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormError />
              </Col>
            </Row>

            <Row>
              <Col cols={6} colsSm={12}>
                <Button
                  type="button"
                  color="secondary"
                  fullWidth
                  onClick={close}
                  disabled={isSubmitting}
                  fullHeight
                >
                  Cancel
                </Button>
              </Col>

              <Col cols={6} colsSm={12} hAlign="flex-end">
                <Button
                  type="submit"
                  fullWidth
                  disabled={!isValid || isSubmitting || !dirty}
                  loading={isSubmitting}
                  data-testid="submit-btn"
                >
                  {`Save Changes to ${pluralize(
                    terms.groupMember,
                    checkedMembers.length,
                    true,
                  )}`}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
