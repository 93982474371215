import { useCurrentUserQuery, useProgramGetByParams } from 'models';

import {
  AppWrapper,
  IconUserAccount,
  IconLogout,
  IconCube,
  Show,
} from '@perts/ui';
import { PagesMenu } from './PagesMenu';
import { BreadcrumbsNavigation } from './BreadcrumbsNavigation';
import { toHome, toHomeNoProgram } from 'pages';
import { TopMenuButtonImpersonate, TopMenu, TopMenuButton } from 'components';
import { usePermissions } from 'utils';

export const PagesTemplate = ({ children }) => {
  let menuTop = <></>;

  const { data: program } = useProgramGetByParams();
  const { data: userAuthenticated } = useCurrentUserQuery();

  const { isAdmin } = usePermissions();

  if (userAuthenticated) {
    menuTop = (
      <TopMenu>
        <TopMenuButtonImpersonate />

        <Show when={isAdmin}>
          <TopMenuButton to="/canvas" color="text">
            <IconCube />
          </TopMenuButton>
        </Show>

        <TopMenuButton
          to={`/users/${userAuthenticated.short_uid}`}
          color="text"
        >
          <IconUserAccount />
        </TopMenuButton>

        <TopMenuButton to="/logout?logout_canvas=true" color="text">
          <IconLogout />
        </TopMenuButton>
      </TopMenu>
    );
  }

  return (
    <AppWrapper
      menu={<PagesMenu />}
      menuTop={<>{menuTop}</>}
      breadcrumbsNavigation={<BreadcrumbsNavigation />}
      program={program}
      toHome={program ? toHome(program.label) : toHomeNoProgram()}
    >
      {children}
    </AppWrapper>
  );
};
