import { useContext } from 'react';
import { useQueryClient } from 'react-query';
import { message } from 'antd';

import {
  GroupMember,
  updateGroupMember,
  queryKeyUsersByGroup,
  invalidateUpdateGroupMember,
  selectUserAccountIsPending,
} from 'models';
import {
  useParams,
  toGroupMembersPin,
  toGroupMembersSendInvitations,
  toGroupMembersManager,
} from 'pages';
import { useOpenModal } from 'utils';

import {
  Card,
  EditInPlaceForm,
  Col,
  IconEnvelope,
  PinButton,
  Row,
  Visible,
  Show,
  IconButton,
  IconUserCircle,
} from '@perts/ui';
import pluralize from 'pluralize';
import TermsContext from 'components/TermsContext';
import { CardButton } from 'components/CardButton';

export type MemberCardProps = {
  /**
   * `true` if the Group is pinned for the GroupMember `member`.
   */
  isPinned?: boolean;

  // The GroupMember to display.
  member: GroupMember;

  // useToggles Toggle JSX
  //   Example: <Toggle id={member.uid} />
  toggle: React.ReactElement | null;

  /**
   * `true` if the currentUser may edit Group Member name.
   */
  mayGroupMemberEditName: boolean;

  /**
   * `true` if the currentUser may pin Group for User `u`.
   */
  mayGroupMemberEditPin: boolean;

  /**
   * Is `true` if the currentUser may send invite to Group members.
   */
  mayGroupMemberSendInvite: boolean;

  /**
   * `true` if the currentUser may promote/demote Group Member `u`.
   */
  mayGroupMemberPromote: boolean;

  checked: { [key: string]: boolean };
};

export const MemberCard = ({
  isPinned,
  mayGroupMemberEditName,
  mayGroupMemberEditPin,
  mayGroupMemberSendInvite,
  mayGroupMemberPromote,
  member,
  toggle,
  checked,
}: MemberCardProps) => {
  const { groupId } = useParams();
  const openModal = useOpenModal();
  const queryClient = useQueryClient();
  const terms = useContext(TermsContext);

  const queryInvalidateFn = () =>
    invalidateUpdateGroupMember(queryClient, groupId);

  const uniqComparator = (previous, current) => previous.uid === current.uid;

  const openGroupMembersSendInvitation = () => {
    // The clicked member card is marked as checked
    openModal(toGroupMembersSendInvitations(groupId), {
      checked: {
        [member.uid]: true,
      },
    });
  };

  return (
    <>
      <Card toggle={toggle} checked={checked[member.uid]}>
        <Card.Title>
          <Row alignItems="center">
            <Col cols={4} colsSm={12}>
              <Card.TitleText overflowWrap="anywhere">
                {member.email}
              </Card.TitleText>
            </Col>

            <Col cols={4} colsSm={12}>
              <Show when={mayGroupMemberEditName}>
                <EditInPlaceForm<GroupMember>
                  name="name"
                  label={`Edit in place ${member.name}`}
                  value={member}
                  emptyValueMessage="No name set"
                  queryKey={queryKeyUsersByGroup(groupId)}
                  queryUpdateFn={updateGroupMember}
                  queryInvalidateFn={queryInvalidateFn}
                  uniqComparator={uniqComparator}
                  successFn={() =>
                    message.success(
                      `Successfully updated the ${terms.groupMember.toLowerCase()}'s name.`,
                    )
                  }
                  errorFn={() =>
                    message.error(
                      `There was an error updating that ${terms.groupMember.toLowerCase()}'s name.`,
                    )
                  }
                />
              </Show>
              <Show when={!mayGroupMemberEditName}>
                <Card.TitleText>{member.name}</Card.TitleText>
              </Show>
            </Col>
            <Col cols={4} colsSm={12}>
              <Card.TitleText>
                {pluralize(
                  terms.class.toLowerCase(),
                  member.classes.length,
                  true,
                )}
              </Card.TitleText>
            </Col>
          </Row>
        </Card.Title>

        <Card.Content>
          <Row alignItems="center">
            <Col cols={4} colsSm={12}>
              <Show
                when={
                  mayGroupMemberSendInvite && selectUserAccountIsPending(member)
                }
              >
                <CardButton
                  color="text"
                  iconLeft={<IconEnvelope />}
                  onClick={openGroupMembersSendInvitation}
                >
                  Send Invitation
                </CardButton>
              </Show>
            </Col>
            <Col colsMd={4} colsSm={12}>
              <Row>
                <Col vAlign="center" shrink>
                  <Show when={member.isManager || mayGroupMemberPromote}>
                    <IconButton
                      aria-label={
                        member.isManager
                          ? 'Click to remove lead'
                          : 'Click to add lead'
                      }
                      icon={<IconUserCircle />}
                      state={member.isManager ? 'active' : 'inactive'}
                      disabled={!mayGroupMemberPromote}
                      onClick={() =>
                        openModal(toGroupMembersManager(groupId), {
                          checked: { [member.uid]: true },
                        })
                      }
                    />
                  </Show>
                </Col>
                <Col vAlign="center">
                  <Show when={member.isManager}>
                    {terms.groupManager.toLowerCase()}
                  </Show>
                </Col>
              </Row>
            </Col>
            <Col colsMd={4} colsSm={12}>
              <Row>
                <Col vAlign="center" shrink>
                  <Visible when={mayGroupMemberEditPin}>
                    <PinButton
                      isPinned={Boolean(isPinned)}
                      onClick={() =>
                        openModal(toGroupMembersPin(groupId), {
                          checked: { [member.uid]: true },
                        })
                      }
                    />
                  </Visible>
                </Col>
                <Col vAlign="center">
                  <Show when={isPinned}>
                    this {terms.group.toLowerCase()} pinned
                  </Show>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Content>
      </Card>
    </>
  );
};
