import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components/macro';
import theme from '../theme';
import { Show } from '../../';
import { IconCheckboxCheck, IconCheckboxIndeterminate } from '../Icon';

const Checkbox = ({
  checked,
  className = '',
  disabled = false,
  indeterminate,
  label,
  ...props
}) => {
  const cx = classNames(className);

  const checkRef = useRef({ checked, indeterminate });

  useEffect(() => {
    checkRef.current.checked = checked;
    checkRef.current.indeterminate = indeterminate;
  }, [checked, indeterminate]);

  return (
    <CheckboxLabel
      as={label ? 'label' : 'span'}
      disabled={disabled}
      htmlFor={props.name}
    >
      <CheckboxContainer className={cx} disabled={disabled}>
        <HiddenCheckbox
          ref={checkRef}
          disabled={disabled}
          id={props.name}
          {...props}
        />
        <StyledCheckbox checked={checked} disabled={disabled}>
          <Show when={indeterminate}>
            <IconCheckboxIndeterminate />
          </Show>

          <Show when={checked && !indeterminate}>
            <IconCheckboxCheck />
          </Show>
        </StyledCheckbox>
      </CheckboxContainer>

      <span>{label}</span>
    </CheckboxLabel>
  );
};

Checkbox.propTypes = {
  // To allow styled-components wrapping.
  className: PropTypes.string,

  // Whether the checkbox is checked.
  checked: PropTypes.bool,

  // Whether the checkbox is disabled.
  disabled: PropTypes.bool,
};

export default Checkbox;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  /*
    Hide the browser default input UI. Don't use 'display: hidden' or
    'visibility: hidden' because then the input fields will be removed from the
    flow of the page and ignored by screen readers.
  */
  opacity: 0;

  /*
    Absolute so the hidden radio fields doesn't displace other elements.
  */
  position: absolute;

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const StyledCheckbox = styled.div`
  width: ${(props) => props.theme.units.checkboxSize};
  height: ${(props) => props.theme.units.checkboxSize};

  background: ${(props) =>
    props.disabled
      ? props.theme.colors.grayLight
      : props.checked
      ? props.theme.colors.white
      : 'transparent'};

  border: 2px solid ${(props) => props.theme.colors.grayDark};
  border-radius: ${(props) => props.theme.units.borderRadius};

  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 5px ${(props) => props.theme.colors.primaryLight};
  }

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
`;

const CheckboxLabel = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;

  > span {
    margin-left: ${(props) => props.theme.units.fieldPaddingInternal};
  }

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

StyledCheckbox.defaultProps = {
  theme,
};

CheckboxLabel.defaultProps = {
  theme,
};
