import { sortBy } from 'lodash';

import { PortalType } from '@perts/model';

import { Class } from 'models';

const orderOfPreference: PortalType[] = [
  PortalType.EMAIL,
  PortalType.GOOGLE,
  PortalType.STUDENT_ID,
];

export const preferredPortalType = (classes: Class[]): PortalType => {
  if (classes.length === 0) {
    throw new Error('preferredPortalType given an empty array');
  }

  const portalTypes = classes.map((cls) => cls.portal_type);

  return sortBy(portalTypes, (t) => orderOfPreference.indexOf(t))[0];
};
