import { useContext } from 'react';
import { differenceWith, isEqual, unionWith, uniq } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { message } from 'antd';

import {
  Class,
  User,
  queryKeyClassesByGroup,
  queryKeyUsersByGroup,
  selectClassFacilitators,
  useClassesQueryByParams,
  useGroupsQueryByClasses,
  useUsersQueryByParams,
  selectClassGroups,
} from 'models';
import { useParams } from 'pages';
import { useCloseModal, useGetCheckedStates } from 'utils';
import getUserName from 'utils/getUserName';

import Loading from 'components/Loading';
import { ErrorMessageBox } from 'components/ErrorMessageBox';

import {
  ClassesEditUsersForm,
  ClassesEditUsersValuesProps,
} from './ClassesEditUsersForm';
import { createUserClassGroupMap } from './createUserClassGroupMapping';
import { usersInviteToClassesGroups } from './usersInviteToClassesGroups';
import { usersRemoveFromClasses } from './usersRemoveFromClasses';
import { toggleStatesOfFacilitatorIds } from './toggleStateOfFacilitatorIds';
import TermsContext from 'components/TermsContext';
import { getMessageFromErrors } from '@perts/util';

export const ClassesEditUsers = () => {
  const checked = useGetCheckedStates();
  const closeModal = useCloseModal();
  const terms = useContext(TermsContext);
  const queryClient = useQueryClient();
  const { groupId } = useParams();
  const queryKeyClasses = queryKeyClassesByGroup(groupId);
  const queryKeyUsers = queryKeyUsersByGroup(groupId);

  // Query for Classes of Group.
  const {
    isLoading: classesIsLoading,
    data: classes = [],
    isError: classesIsError,
    error: classesError,
  } = useClassesQueryByParams();

  // Query for Users of Group.
  const {
    isLoading: usersIsLoading,
    data: users = [],
    isError: usersIsError,
    error: usersError,
  } = useUsersQueryByParams();

  // Query for Groups associated with the Classes.
  const {
    isLoading: classGroupsIsLoading,
    isError: classGroupsIsError,
    data: classGroups = [],
    error: classGroupsError,
  } = useGroupsQueryByClasses(classes);

  // Email is shown as an alternative if the facilitator does not have a name
  const usersFilled: User[] = users.map((user) => ({
    ...user,
    name: getUserName(user),
  }));

  // Add facilitators and groups to each Class.
  const classesFilled: Class[] = classes.map((cls) => ({
    ...cls,
    groups: selectClassGroups(cls, classGroups),
    facilitators: selectClassFacilitators(cls, usersFilled),
  }));

  // Filter to classes that have been selected.
  const classesChecked: Class[] = classesFilled.filter(
    (cls) => checked[cls.uid],
  );

  // Generate an array of class lead, class, and group combinations. This will
  // be used later to determine which classes/groups to add/remove users from.
  const userClassGroupMapCurrent = createUserClassGroupMap(classesChecked);

  // Mutation: Edit facilitators from classes.
  // https://react-query.tanstack.com/guides/mutations
  const mutation = useMutation(
    async (values: ClassesEditUsersValuesProps) => {
      const { owned_teams = {} } = values;

      // Users array with updated owned_teams provided by the form.
      const usersFromForm = usersFilled.map((user) => {
        // Important. The form only returns user owned_teams associated with the
        // checked classes. This means we should only remove IDs from the user
        // owned_teams if the ID is associated with a checked class.

        // Only remove items from user's owned_teams if they BOTH
        // - are associated with a checked class
        // - do not appear in form's owned_teams
        const teamIdsToRemove = classesChecked
          .filter((cls) => !owned_teams[user.uid].includes(cls.uid))
          .map((c) => c.uid);

        // Add items to user's owned_teams if they BOTH
        // - appear in form.owned_teams
        // - do not appear in user.owned_teams
        const teamIdsToAdd = owned_teams[user.uid].filter(
          (teamId) => !user.owned_teams.includes(teamId),
        );

        return {
          ...user,
          owned_teams: [
            // remove teamIds found in teamIdsToRemove
            ...user.owned_teams.filter((tId) => !teamIdsToRemove.includes(tId)),
            // add teamIds found in teamIdsToAdd
            ...teamIdsToAdd,
          ],
        };
      });

      // Classes array with updated facilitators based on updated users.
      const classesCheckedFromForm = classesChecked.map((cls) => ({
        ...cls,
        facilitators: selectClassFacilitators(cls, usersFromForm),
      }));

      // Generate a new array of class lead, class, and group combinations.
      const userClassGroupMapFromForm = createUserClassGroupMap(
        classesCheckedFromForm,
      );

      // Update each user's owned_organizations. Here, we want to add (but not
      // remove) any groups that are associated with classes the user is being
      // added/invited to.
      usersFromForm.forEach((user) => {
        // This users new groups (organizations).
        const usersOrganizationIdsFromMapping = userClassGroupMapFromForm
          .filter((mapping) => mapping.userId === user.uid)
          .map((mapping) => mapping.groupId);

        // Merge the existing and new owned_organizations, remove duplicates.
        user.owned_organizations = uniq([
          ...user.owned_organizations,
          ...usersOrganizationIdsFromMapping,
        ]);
      });

      // Determine the additions/invites needed.
      const invites = differenceWith(
        userClassGroupMapFromForm,
        userClassGroupMapCurrent,
        isEqual,
      );

      // Determine the removals needed.
      const removes = differenceWith(
        userClassGroupMapCurrent,
        userClassGroupMapFromForm,
        isEqual,
      );

      // Snapshot the previous classes value.
      const previousClasses =
        queryClient.getQueryData<Class[]>(queryKeyClasses);

      // Snapshot the previous users value.
      const previousUsers = queryClient.getQueryData<Class[]>(queryKeyUsers);

      // Optimistically update
      if (previousClasses) {
        // Merge user updates from form into existing users.
        const usersOptimistic = unionWith(
          usersFromForm,
          usersFilled,
          (updatingTo, existing) => existing.uid === updatingTo.uid,
        );

        // Merge class updates from form into existing classes.
        const classesOptimistic = unionWith(
          classesCheckedFromForm,
          // Important. Use all classes here, not just checked classes.
          classesFilled,
          (updatingTo, existing) => existing.uid === updatingTo.uid,
        );

        queryClient.setQueryData(queryKeyUsers, usersOptimistic);
        queryClient.setQueryData(queryKeyClasses, classesOptimistic);
      }

      // Perform adds and removes simultaneously.
      await Promise.all([
        // Invite users to classes/groups.
        usersInviteToClassesGroups(
          invites,
          usersFromForm,
          classesCheckedFromForm,
        ),

        // Remove users from classes.
        usersRemoveFromClasses(removes, usersFromForm),
      ]);

      // Return context for rollbacks.
      return { previousUsers, previousClasses };
    },
    {
      // Handle successful mutation.
      onSuccess: (data) => {
        message.success(
          `Successfully edited ${terms.classManagers.toLowerCase()} for ` +
            `${terms.classes.toLowerCase()}.`,
        );
      },
      // If the mutation fails,
      // use the context returned from onMutate to roll back
      onError: (err, data, context: any) => {
        queryClient.setQueryData(queryKeyUsers, context.previousUsers);
        queryClient.setQueryData(queryKeyClasses, context.previousClasses);
      },
    },
  );

  // Display loading.
  const isLoading = usersIsLoading || classesIsLoading || classGroupsIsLoading;

  if (isLoading) {
    return <Loading />;
  }

  // Display errors.
  if (usersIsError || classesIsError || classGroupsIsError) {
    return (
      <ErrorMessageBox>
        {getMessageFromErrors([usersError, classesError, classGroupsError])}
      </ErrorMessageBox>
    );
  }

  // Create checked/indeterminate arrays of facilitator (user) UIDs.
  const {
    checked: checkedFacitilitatorIds,
    indeterminate: indeterminateFacilitatorIds,
  } = toggleStatesOfFacilitatorIds(classesChecked);

  // https://stackoverflow.com/questions/65760158/react-query-mutation-typescript
  // Formik onSubmit handler
  const onSubmit = async (values: ClassesEditUsersValuesProps) => {
    await mutation.mutateAsync(values);
  };

  return (
    <ClassesEditUsersForm
      close={closeModal}
      onSubmit={onSubmit}
      classes={classesChecked}
      facilitators={usersFilled}
      checkedFacitilitatorIds={checkedFacitilitatorIds}
      indeterminateFacilitatorIds={indeterminateFacilitatorIds}
    />
  );
};
